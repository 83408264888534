import React, { memo } from "react";
import { Link } from "react-router-dom";

const PageTitleSection = (props) =>{
    return(
        <>
            {/* <!-- Page Title Section Start --> */}
            <div className="page-title-section section section-padding-top pt-10" data-bg-color="#000" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} data-overlay="0.7" aria-label={props.pagetitle} >
                <div className="page-breadcrumb position-static">
                    <div className="container">
                        <ul className="breadcrumb justify-content-center">
                            <li><Link to='/'>Home</Link></li>
                            <li className="current">{props.pagetitle}</li>
                        </ul>
                        {props.LogoImage ? <img src={process.env.PUBLIC_URL + props.LogoImage} alt={props.title} className="logoTopPageHeader" /> : '' }
                        
                    </div>
                </div>
            <div className="page-title">
                <div className="container">
                        <h1 className="title">{props.title}</h1>
                        <p className="mt-3 text-center text-white">
                            {props.subtitle}
                        </p>
                </div>
            </div>            
        </div>
        {/* <!-- Page Title Section End --> */}
        </>
    )
}

export default memo(PageTitleSection);