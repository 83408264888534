import React from 'react';
import { authProvider } from '../../AuthProvider';
import AzureAD from 'react-aad-msal';
import DashboardHeader from '../../Componenets/Dashboard/DashboardHeader';
import Sidebar from '../../Componenets/Dashboard/Sidebar';

export default function Home() {
    return (
        <>
            <DashboardHeader />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel mainPanelDashboard">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                Home
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
