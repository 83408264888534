import React, { memo, useEffect } from "react";
import Header from '../Componenets/Header';
import Slider from '../Componenets/Slider';
import ServiceSection from '../Componenets/ServiceSection';
import NewSuccessSection from "../Componenets/NewSuccessSection";
import NewsLetterSection from '../Componenets/NewsLetterSection';
import FooterSection from '../Componenets/FooterSection';
import ServicesInformationSection from "../Componenets/ServicesInformationSection";
import AboutTextSection from "../Componenets/AboutTextSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";
import OurAccreditationSection from "../Componenets/OurAccreditationSection";

const HomePage = () => {
    const titleWebsite = "Xetech";
    const descriptionWebsite = "We are a self-absorbed digital transformation and software engineering company focusing on various asepcts of healthcare and IT solutions including, Cloud Services, IoT, Applications, Integration, Browser Application, Programming Expertise.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);
    return (
        <>
            <Header />
            <Slider
                //titleOne="Better Solutions at Your Fingertips"
                //subtitleOne="We have one mission to be the Best IT Software and Services company"
                //bannerOne="assets/images/slider/slider-1.jpg"
                //titleTwo="Data-Driven Product Management"
                //subtitleTwo="Bringing together technology products, people and processes to help you achieve the optimum business performance"
                //bannerTwo="assets/images/slider/slider-2.jpg"
                //titleThree="Enterprise solutions"
                //subtitleTree="We design, build and integrate modern enterprise applications."
                //bannerThree="assets/images/slider/slider-3.jpg"
                titleOne="We design, build and integrate modern enterprise applications."
                subtitleOne="We have one mission to be the Best IT Software and Services company"
                bannerOne="assets/images/slider/slider-next.jpg"
                titleTwo="Data-Driven Product Management"
                subtitleTwo="Bringing together technology products, people and processes to help you achieve the optimum business performance"
                bannerTwo="assets/images/slider/slider-2.jpg"
                titleThree="Enterprise solutions"
                subtitleTree="Better Solutions at Your Fingertips"
                bannerThree="assets/images/slider/slider-3.jpg"
            />
            <ServicesInformationSection
                title="Leveraging tech to drive a better IT experience"
                subtitle="Our team of designers, developers and creatives are perfectionists who love what they do and love where they work"
                altOne="Software Development"
                iconOne="assets/images/svg-icons/software-development.svg"
                altTwo="Cloud Solutions"
                iconTwo="assets/images/svg-icons/cloud-solutions.svg"
                altThree="Business"
                iconThree="assets/images/svg-icons/business.svg"
                altIconOne="Solutions"
                iconFour="assets/images/svg-icons/solutions.svg"
            />
            <AboutTextSection
                title="Our Services"
                subtitle="Creating over thousands of success stories with a streamlined pro software development process for over extensive years of excellence."
                buttontext="Read More"
                altOne="Small One"
                imageOne="assets/images/pages/small-one.jpg"
                altTwo="Small Two"
                imageTwo="assets/images/pages/small-two.jpg"
                imageThree="assets/images/shape-animation/about-shape-1.png"
                url="services"
            >
                Entrusted by our clients, our Microsoft certified Software developers deliver customized software solutions that are intelligent, efficacious and cost-effective. Committed to providing quality service, we deal with all or any part of your software development process; initiating with project planning, using a blend of dedicated agile methodologies & in-house project control techniques, detailed technical design, groundbreaking coding with superb quality assurance.
            </AboutTextSection>
            <ServiceSection
                title="Our Story"
                subtitle="We are the XeTech family, among one of the top conquering business development and digital transforming companies to shape up your business in all professional aspects. Grown and matured by versatile industry-led challenges, requirements, our UK and India based team work relentlessly with recently advanced and updated software and technologies to empower your business with state of art technology."
                altIconOne="Result Driven"
                iconOne="assets/images/svg-icons/result-driven.svg"
                urlOne="about"
                altIconTwo="Tailor Made"
                iconTwo="assets/images/svg-icons/tailor-made.svg"
                urlTwo="about"
                altIconThree="Enhanced"
                iconThree="assets/images/svg-icons/enhanced.svg"
                urlThree="about"
                altIconFour="Company"
                iconFour="assets/images/svg-icons/company.svg"
                urlFour="about"
            />
            <NewSuccessSection
                toptitle="Our Technologies"
                title="Providing each and every customer, big or small, with unbiased services and uncompromising high quality of work."
                position="left"
                altOne="Mobile Development"
                imageOne="assets/images/technologies/mobile-development.jpg"
                iconOne="assets/images/svg-icons/mobile-development.svg"
                titleOne="Technology"
                subtitleOne="Mobile Development"
                urlOne="/mobiledevelopment"
                altTwo="Cloud Services"
                imageTwo="assets/images/technologies/cloud-services.jpg"
                iconTwo="assets/images/svg-icons/cloud-service.svg"
                titleTwo="Technology"
                subtitleTwo="Cloud Services"
                urlTwo="/cloudservices"
                altThree="Application"
                imageThree="assets/images/technologies/application.jpg"
                iconThree="assets/images/svg-icons/application.svg"
                titleThree="Technology"
                subtitleThree="Applications"
                urlThree="/applications"
                altFour="Database"
                imageFour="assets/images/technologies/database.jpg"
                iconFour="assets/images/svg-icons/database.svg"
                titleFour="Technology"
                subtitleFour="Database"
                urlFour="/database"
                altFive="Browser"
                imageFive="assets/images/technologies/browser.png"
                iconFive="assets/images/svg-icons/browser-application.svg"
                titleFive="Technology"
                subtitleFive="Browser Applications"
                urlFive="/browserapplications"
                altSix="Programing"
                imageSix="assets/images/technologies/programming.jpg"
                iconSix="assets/images/svg-icons/programming-expertise.svg"
                titleSix="Technology"
                subtitleSix="Programming Expertise"
                urlSix="/programmingexpertise"
            />
            <OurAccreditationSection />
            <NewsLetterSection
                title="Ready for the future"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
                //urlSixteen="assets/images/brand-logo/jhah-logo.png"
                //altSixteen="Johns Hopkins Aramco Healthcare"
                urlSeventeen="assets/images/brand-logo/saggafeye-logo.png"
                altSeventeen="Saggaf Eye Center"
                urlEighteen="assets/images/brand-logo/sumitomo.png"
                altEighteen="Sumitomo Rubber USA"

            />
            <FooterSection />
        </>
    )
}

export default memo(HomePage);