import React, { memo } from "react";
import { Link } from "react-router-dom";

const NewServicesSixPageSection = (props) => {
    return (
        <>
            {/* <!-- Service Section Start -->*/}
            <div className="section section-padding-t90 section-padding-bottom">
                <div className="container">

                    {/* <!-- Section Title Start -->*/}
                    <div className="section-title text-center"
                    // data-aos="fade-up"
                    >
                        <h2 className="title fz-32">{props.title}</h2>
                        <p className="sub-title title-w-90">{props.subTitle}</p>
                    </div>
                    {/* <!-- Section Title End -->*/}

                    {/* <!-- About Wrapper Start -->*/}
                    <div className="row mb-n6 icon-box-shape-animation">

                        {/* <!-- Animation Shape Start -->*/}
                        <div className="shape shape-1 scene">
                            <span data-depth="1"><img src="assets/images/shape-animation/video-shape-1.png" alt="Shape" /></span>
                        </div>
                        {/* <!-- Animation Shape End -->*/}
                        {/* <div className="col-md-5 mb-10">
                    <div className="section-title-two">
                        <span className="sub-title"></span>
                        <h3 className="title font-big-contact ">A solution for every complex challenge</h3>
                        </div>
                    <p>
                        We strive to deliver excellence across a wide spectrum of technology. Our services comprise of software conceptualization, software architecture & design, software development, database development, cloud computing, software migration, application maintenance & testing. Our smart and enthusiastic team helps the businesses accelerate their efficiency using the latest technologies.
                    </p>
                    </div> */}
                        <div className="col-md-12">
                            <div className="row story-icon-redefined">
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageOne})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt={props.titleOne} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlOne}>
                                                <h3 className="title p-2 text-left">{props.titleOne}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentOne}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlOne}>Learn More</Link>
                                    </div>
                                </div>
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageTwo})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt={props.titleTwo} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlTwo}>
                                                <h3 className="title p-2 text-left">{props.titleTwo}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentTwo}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlTwo}>Learn More</Link>
                                    </div>
                                </div>
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageThree})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt={props.titleThree} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlThree}>
                                                <h3 className="title p-2 text-left">{props.titleThree}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentThree}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlThree}>Learn More</Link>
                                    </div>
                                </div>
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageFour})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFour}`} alt={props.titleFour} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlFour}>
                                                <h3 className="title p-2 text-left">{props.titleFour}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentFour}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlFour}>Learn More</Link>
                                    </div>
                                </div>
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageFive})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFive}`} alt={props.titleSix} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlFive}>
                                                <h3 className="title p-2 text-left">{props.titleFive}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentFive}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlFive}>Learn More</Link>
                                    </div>
                                </div>
                                <div className="col col-md-4 mb-6">
                                    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">
                                        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageSix})` }}></div>
                                        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconSix}`} alt={props.titleSix} /></div>
                                        <div className="content pl-5 align-items-center justify-content-center d-flex">
                                            <Link to={props.urlSix}>
                                                <h3 className="title p-2 text-left">{props.titleSix}</h3>
                                            </Link>
                                        </div>
                                        <p className="p-2 pb-0 pt-0 text-white content-relative">
                                            {props.contentSix}
                                        </p>
                                        <Link className="iconlinktexttechno  content-relative" to={props.urlSix}>Learn More</Link>
                                    </div>
                                </div>


                                {/*<div className="col col-md-4 mb-6 flex-column">*/}
                                {/*    <div className="icon-box imageServiceOverlayEffect text-center box-shadow-effect flex-column">*/}
                                {/*        <div className="overlayImageService" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageSix})` }}></div>*/}
                                {/*        <div className="icon icon-center w-100"><img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconSix}`} alt={props.titleSix} /></div>*/}
                                {/*        <div className="content pl-5 align-items-center justify-content-center d-flex">*/}
                                {/*            <Link to={props.urlSix}>*/}
                                {/*                <h3 className="title p-2 text-left">{props.titleSix}</h3>*/}
                                {/*            </Link>*/}
                                {/*        </div>*/}
                                {/*        <p className="p-2 pb-0 text-white content-relative">*/}
                                {/*            {props.contentSix}*/}
                                {/*        </p>*/}
                                {/*        <Link className="iconlinktexttechno content-relative" to={props.urlSix}>Learn More</Link>*/}
                                {/*    </div>*/}
                                {/*</div>*/}



                            </div>
                        </div>

                    </div>
                    {/* <!-- About Wrapper End -->*/}

                </div>
            </div>
            {/* <!-- Service Section End -->*/}
        </>
    )
}

export default memo(NewServicesSixPageSection);