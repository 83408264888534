import React, { memo } from "react";

const FunfactSection = (props) =>{
    return(
        <>
         {/* <!-- Funfact Section Start --> */}
        <div className="section section-padding-t90 section-padding-bottom d-none " style={{backgroundColor:'#f8faff'}} data-bg-color="#f8faff">
            <div className="container">
                {/* <!-- Section Title Start --> */}
                <div className="section-title text-center"
                // data-aos="fade-up"
                 >
                    <h2 className="title fz-32">{props.heading}</h2>
                </div>
                {/* <!-- Section Title End --> */}
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">

                            {/* <!-- Funfact Start --> */}
                            <div className="col mb-6"
                            // data-aos="fade-up"
                            >
                                <div className="funfact">
                                    <div className="number"><span className="counter">{props.titleOne}</span></div>
                                    <h6 className="text">{props.subTitleOne}</h6>
                                </div>
                            </div>
                            {/* <!-- Funfact End --> */}

                            {/* <!-- Funfact Start --> */}
                            <div className="col mb-6"
                            // data-aos="fade-up"
                            >
                                <div className="funfact">
                                <div className="number"><span className="counter">{props.titleTwo}</span></div>
                                    <h6 className="text">{props.subTitleTwo}</h6>
                                </div>
                            </div>
                            {/* <!-- Funfact End --> */}

                            {/* <!-- Funfact Start --> */}
                            <div className="col mb-6"
                            // data-aos="fade-up"
                            >
                                <div className="funfact">
                                <div className="number"><span className="counter">{props.titleThree}</span></div>
                                    <h6 className="text">{props.subTitleThree}</h6>
                                </div>
                            </div>
                            {/* <!-- Funfact End --> */}

                            {/* <!-- Funfact Start --> */}
                            <div className="col mb-6"
                            // data-aos="fade-up"
                            >
                                <div className="funfact">
                                <div className="number"><span className="counter">{props.titleFour}</span></div>
                                    <h6 className="text">{props.subTitleFour}</h6>
                                </div>
                            </div>
                            {/* <!-- Funfact End --> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Funfact Section End --> */}
        </>
    )
}

export default memo(FunfactSection);