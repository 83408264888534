import React, { memo } from "react";

const AboutUsPageSection = (props) =>{
    return(
        <>
         {/* {/* <!-- About Section Start --> */}
        <div className="section section-padding-top about-section-padding-bottom-100 mb-15">
            <div className="container">

                {/* <!-- About Wrapper Start --> */}
                <div className="row">

                    {/* <!-- About Start --> */}
                    <div className="col-xl-6 col-lg-6 col-12"
                    // data-aos="fade-up"
                    >
                        {/* <!-- About Content Area Start --> */}
                        <div className="about-content-area mt-0 mb-md-10 mb-10">
                            {/* <!-- Section Title Two Start --> */}
                            <div className="section-title-two">
                                <span className="sub-title">{props.title}</span>
                                <h3 className="title">{props.subTitle}</h3>
                            </div>
                            {/* <!-- Section Title Two End --> */}

                            <div className="row row-cols-sm-2 row-cols-auto mb-n6 story-icon-redefined-about">
                                {/* <!-- Single About Funfuct Start --> */}
                                <div className="col mb-6"
                                // data-aos="fade-up"
                                >
                                    <div className="about-funfact">
                                        <div className="number">
                                                <div className="icon"><img className="svgInject" src={process.env.PUBLIC_URL + props.iconOne} alt={props.altIconOne} /></div>
                                        </div>
                                        <h6 className="text">{props.titleOne}</h6>
                                        <p>{props.subtitleOne}</p>
                                    </div>
                                </div>
                                {/* <!-- Single About Funfuct Start --> */}
                                {/* <!-- Single About Funfuct Start --> */}
                                <div className="col mb-6"
                                // data-aos="fade-up"
                                >
                                    <div className="about-funfact">
                                        <div className="number">
                                                <div className="icon">
                                                    <img className="svgInject" src={process.env.PUBLIC_URL + props.iconTwo} alt={props.altIconTwo} />
                                            </div>
                                        </div>
                                        <h6 className="text">{props.titleTwo}</h6>
                                        <p>{props.subtitleTwo}</p>
                                    </div>
                                </div>
                                {/* <!-- Single About Funfuct Start --> */}
                            </div>
                        </div>
                        {/* <!-- About Content Area Start --> */}
                    </div>
                    {/* <!-- About Start --> */}

                    {/* <!-- About Image Area Start --> */}
                    <div className="col-xl-6 col-lg-6 col-12"
                    // data-aos="fade-up"
                    >
                        <div className="about-image-area about-shape-animation right-0 mr-0">
                            <div className="about-image js-tilt">
                                    <img src={process.env.PUBLIC_URL + props.imageOne} alt={props.altOne} />
                            </div>
                            <div className="about-image js-tilt">
                                    <img src={process.env.PUBLIC_URL + props.imageTwo} alt={props.altTwo} />
                            </div>
                            {/* <!-- Animation Shape Start --> */}
                            <div className="shape shape-1 scene">
                                    <span data-depth="1"><img src="assets/images/shape-animation/video-shape-1.png" alt="Shape" /></span>
                            </div>
                            {/* <!-- Animation Shape End --> */}
                        </div>
                    </div>
                    {/* <!-- About Image Area Start --> */}

                </div>
                {/* <!-- About Wrapper End --> */}

            </div>
        </div>
        {/* <!-- About Section End --> */}
        </>
    )
}

export default memo(AboutUsPageSection);