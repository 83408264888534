import React, { memo } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const NewSuccessSection = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        /*autoplay: true,*/
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="container-fluid sliderTechnoNews slider-swipper slider-swiper-main overflow-hidden slider-pading-bot-50">
                <div className="row pt-15">
                    <div className="col-md-12">
                        <div className="section-title text-center"
                        // data-aos="fade-up"
                        >
                            <h2 className="title">{props.toptitle}</h2>
                            {/* <p className="sub-title title-w-90">{props.subtitle}</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div>
                        <Slider {...settings}>
                            <div className="slide-Box">
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageOne})` }} aria-label={props.altOne} >
                                    <Link to={props.urlOne}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleOne}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res">
                                                {props.subtitleOne}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconOne} className="ourAccertationIcon" />
                                            </p>
                                        </div>

                                    </Link>
                                </div>
                            </div>
                            <div className="slide-Box" >
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageTwo})` }} aria-label={props.altTwo}>
                                    <Link to={props.urlTwo}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleTwo}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res">
                                                {props.subtitleTwo}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconTwo} className="ourAccertationIcon" />
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-Box">
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageThree})` }} aria-label={props.altThree}>
                                    <Link to={props.urlThree}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleThree}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res">
                                                {props.subtitleThree}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconThree} className="ourAccertationIcon" />
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-Box">
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageFour})` }} aria-label={props.altFour} >
                                    <Link to={props.urlFour}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleFour}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res">
                                                {props.subtitleFour}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconFour} className="ourAccertationIcon" />
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-Box">
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageFive})` }} aria-label={props.altFive} >
                                    <Link to={props.urlFive}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleFive}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res">
                                                {props.subtitleFive}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconFive} className="ourAccertationIcon" />
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="slide-Box">
                                <div className="sliderSlickInnerImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.imageSix})` }} aria-label={props.altSix} >
                                    <Link to={props.urlSix}>
                                        <div className="text-box-details flex-row justify-content-between align-items-center">
                                            {/*<span className="text-bold font-14px">*/}
                                            {/*    {props.titleSix}*/}
                                            {/*</span>*/}
                                            <p className="text-bold mb-0 fix-text-in-res ">
                                                {props.subtitleSix}
                                            </p>
                                            <p className="mb-0 ourAccertationIconBox">
                                                <img src={props.iconSix} className="ourAccertationIcon" />
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(NewSuccessSection);