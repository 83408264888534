import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import FooterSection from "../Componenets/FooterSection";

const PrivacyPolicyPage = () => {
    const titleWebsite = "Privacy and Policy | Xetech";
    const descriptionWebsite = "This is Work Page";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);
    return (
        <>
            <Header />
            <PageTitleSection
                title="Privacy Policy"
                pagetitle="Privacy Policy"
                bgImage="assets/images/pages/work.png"
            />
            <section className="section">
                <div className="container mt-10 mb-10 ">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>
                                Introduction
                            </h1>
                            <p>
                                Welcome to Xetech’ s website privacy policy. Xetech is a trading name of <br />Xetech Solutions Ltd No.12432363 Registered in England, 124 City Road, London, EC1V 2NX, Xetech Pvt.Ltd No U72200DL2012PTC238656 Registered in India, C-56/32, Sector 62, Noida, 201301<br />Xetech has worked in the information technology arena for over 12 years. We are an emerging technology company specialising in healthcare, cloud and software automation. We respect the privacy of every user of this website. Therefore In all matters of compliance, we act with complete transparency and the requirements of data protection, data security and data privacy in line with our information security management system ISO 27001.<br />We intend to make this privacy policy easy to follow, easy to read and validate. However, please get in touch with us should you require any further information. <br /><br />124 City Road, <br />London, <br />EC1V 2NX<br />Admin@xetech.com
                            </p>
                            <h2>
                                Data collection and use
                            </h2>
                            <p>We will collect personal data or information expressly and voluntarily provided by a visitor on the site, we collect the data shown in the comments form and the visitor&#8217;s IP address along with questions, queries, or feedback you leave. It may further include names, titles, company addresses, email addresses, and telephone and fax numbers. if you send an email to us. We further store information on how you use the site, using cookies and page tagging techniques to help us improve the website.</p>
                            <p>We will only use your personal information when the law allows us to. Most commonly, we will use your personal information in the following circumstances:<br />Where we need to perform the contract we have entered with you.<br />Where we need to comply with a legal obligation. It is necessary for our legitimate interests (or those of a third party) and your interests that fundamental rights do not override those interests.<br />To send you personalised communications which we believe may be of interest to you, including our product and services, developments, webinars, events, surveys, or relevant company information.<br />To improve our website<br />To answer your queries or feedback</p>
                            <p>We will not share any data or information with any third parties. Any information we shared on our website and in our communication is of legitimate interest to you and may include information such as promotion and information about our products and services, thought leadership, views and insights, and knowledge of how other users have succeeded working with us.</p>
                            <h2>
                                How long will you retain my personal information?
                            </h2>
                            <p>We will hold your personal information if you use our products and services or comply with our legal obligations.</p>
                            <p>For personal information used by us for our direct marketing purposes, we will retain the data whilst accurate, unless you will request to be unsubscribed/removed from our database.</p>
                            <h2>Cookies</h2>
                            <p>
                                The website uses “cookies” to help personalise your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. This cookie contains no personal data and is discarded when you close your browser.
                            </p>
                            <p>
                                If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and indicates the post ID of the article you just edited. It expires after one day.
                            </p>
                            <p>
                                We also may permit certain third-party companies to help collect data logs of your activity that we think may interest users and collect and use other data about user activities on our website.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterSection />
        </>
    )
}

export default memo(PrivacyPolicyPage);