import React from 'react';
import { Link } from 'react-router-dom';

export default function Sidebar() {
    return (
        <>
            <div className="sidebarPanel">
                <div className="logoSidebar">
                    <img className="logoDashboard" src={process.env.PUBLIC_URL + 'assets/images/logo/footer-logo.svg'} alt="Xetech" /> :
                </div>
                <ul className="nav">
                    <li className="nav-item">
                        <Link to='/dashboard' className="nav-link" data-bs-toggle="collapse" href="" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Home</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to='/dashboardbanners' className="nav-link" data-bs-toggle="collapse" href="" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Banners</span>
                        </Link>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Services</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Home</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="collapse" href="" aria-expanded="false"
                            aria-controls="home">
                            <i className="menu-icon mdi mdi-floor-plan"></i>
                            <span className="menu-title">Home</span>
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}
