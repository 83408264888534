import React, { Component, memo } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

class ContactFormSection extends Component {

    componentDidMount() {
        loadCaptchaEnginge(4);
    };

    constructor(props) {
        super(props)

        this.state = {
            Name: '',
            ToEmail: '',
            Subject: '',
            Body: '',
            ErrorMsg: '',
            Popup: 'none',
            EPopUp: 'none'
        }
    }

    handlerNameChange = (event) => {
        this.setState({
            Name: event.target.value
        })
    }
    handlerEmailChange = (event) => {
        this.setState({
            ToEmail: event.target.value
        })
    }
    handlerSubjectChange = (event) => {
        this.setState({
            Subject: event.target.value
        })
    }
    handlerMsgChange = (event) => {
        this.setState({
            Body: event.target.value
        })
    }
    submitHandle = (event) => {
        event.preventDefault()
        const data = { "Name": this.state.Name, "ToEmail": this.state.ToEmail, "Subject": this.state.Subject, "Body": this.state.Body };
        /*console.log(data);*/

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;



        if (!this.state.ToEmail || regex.test(this.state.ToEmail) === false) {
            this.setState({
                ErrorMsg: "Email Id is in correct."
            })
            return false;
        } else {
            if (this.state.Name === '' || this.state.ToEmail === '' || this.state.Subject === '' || this.state.Body === '') {
                this.setState({
                    ErrorMsg: "Some Field are unfilled."
                })
            } else {
                let user_captcha = document.getElementById('user_captcha_input').value;

                if (validateCaptcha(user_captcha) === false) {
                    loadCaptchaEnginge(4);
                }

                if (validateCaptcha(user_captcha) === true) {
                    loadCaptchaEnginge(4);

                    document.getElementById('user_captcha_input').value = "";

                    this.setState({
                        EPopUp: 'flex'
                    })

                    /* api call start */
                    fetch("email", {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                        .then((result) => {

                            let statusCode = result.status,
                                success = result.ok;


                            if (success) {
                                this.setState({
                                    Popup: "show",
                                    EPopUp: 'none'
                                })
                                this.setState({
                                    ErrorMsg: ""
                                })
                                console.log(this.state.Popup, this.state.ErrorMsg)
                            }
                        })
                        .catch((e) => {
                            this.setState({
                                ErrorMsg: e
                            })
                        })
                    /* api call end */
                } else if (user_captcha === '') {
                    this.setState({
                        ErrorMsg: "Please fill captcha."
                    })
                }
                else {
                    this.setState({
                        ErrorMsg: "Captcha Does Not Match."
                    })
                }
            }
        }
    }
    render() {
        return (
            <>
                <div className="popupShowEarlier" style={{ display: this.state.EPopUp === 'none' ? 'none' : 'flex' }} >
                    <div className="loaderForm">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <form action="" id="contact-form" onSubmit={this.submitHandle}>
                    <div className="row mb-n6">
                        <div className="col-md-6 col-12 mb-6">
                            <input type="text" placeholder="Your Name *" minLength="5" value={this.state.Name} pattern="[a-zA-Z][a-zA-Z ]{2,}" onChange={this.handlerNameChange} title="Name should be a proper person name with first name and last name." name="name" required />
                        </div>
                        <div className="col-md-6 col-12 mb-6">
                            <input type="email" placeholder="Email *" value={this.state.ToEmail} onChange={this.handlerEmailChange} name="email" required />
                        </div>
                        <div className="col-md-12 col-12 mb-6">
                            <input type="text" placeholder="Subject *" value={this.state.Subject} onChange={this.handlerSubjectChange} name="subject" required />
                        </div>
                        <div className="col-12 mb-6">
                            <textarea name="message" placeholder="Message *" value={this.state.Body} onChange={this.handlerMsgChange} required></textarea>
                        </div>
                        <div className="col-md-6 text-center">
                            <LoadCanvasTemplate />
                        </div>
                        <div className="col-md-6 mb-2">
                            <div><input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                        </div>
                        <div className="col-6 text-left mb-6 vertical-align-center d-flex justify-content-start align-items-center">
                            <p className="text-danger text-center w-100" >
                                {this.state.ErrorMsg}
                            </p>
                        </div>
                        <div className="col-6 text-right mb-6">
                            <button className="btn btn-primary btn-hover-secondary">Submit</button>
                        </div>
                    </div>
                </form>
                <div className={`modalPopupDesign modal fade ${this.state.Popup === 'none' ? '' : 'show'}`} style={{ display: this.state.Popup === 'none' ? 'none' : 'block' }}  >
                    <div className="modal-dialog modal-dialog-centered" tabIndex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Thanks for Contact Us</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                                        this.setState({
                                            Name: '',
                                            ToEmail: '',
                                            Subject: '',
                                            Body: '',
                                            ErrorMsg: '',
                                            Popup: 'none',
                                            EPopUp: 'none'
                                        })

                                    }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>We've recieved your email and will contact you back shortly.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal"
                                        onClick={() => {
                                            this.setState({
                                                Name: '',
                                                ToEmail: '',
                                                Subject: '',
                                                Body: '',
                                                ErrorMsg: '',
                                                Popup: 'none',
                                                EPopUp: 'none'
                                            })
                                        }}
                                    >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default memo(ContactFormSection);
