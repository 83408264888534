import React, { memo } from 'react';

const IconWorkCareHubSection = (props) => {
    return (
        <>
            <div className="section section-padding-t90 mb-20">
                <div className="container">
                    <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 story-icon-redefined-row">
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleOne}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleTwo}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleThree}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFour}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleFour}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFive}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleFive}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconSix}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleSix}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconSeven}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleSeven}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconEight}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleEight}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconNine}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleNine}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTen}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleTen}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconEleven}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleEleven}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTweleve}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleTweleve}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThirteen}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleThirteen}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFourteen}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleFourteen}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFifteen}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleFifteen}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col mb-6">
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconSixteen}`} alt="Xetech" />
                                </div>
                                <div className="info">
                                    <h4 className="title mt-2">{props.titleSixteen}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default memo(IconWorkCareHubSection);