import React, { memo } from 'react';

const ManagesServicesAboutTextSection = () => {
    return (
        <>
            <div className="section pb-5 pt-5" style={{ backgroundColor: '#f8faff' }} >
                <div className="container shape-animate slider-brand-all overflow-hidden">
                    <div className="row">
                        <div className="col-md-3 justify-content-center align-items-center d-flex">
                            <div className="section-title text-left">
                                <h2 className="title">Managed Services</h2>
                            </div>
                        </div>
                        <div className="col-md-9 pt-5 pb-5">
                            <p>
                                Any business or Enterprises looking to adopt the cloud into their infrastructure will have a lot of cloud services to manage. From the initial migration to automating business workflows in the cloud, the list of cloud computing is endless. Our experts ensure you get the best out of cloud platform you select. We fill the gaps in a businesses technical skillset and shortcomings, so you can be assured your cloud management is handled by experts that fully know the cloud environment you are using.
                                <br /><br />We have extensive knowledge into how the cloud environments function includes AWS and Azure and being Microsoft certified gold partner; Azure is our area of expertise. However, the industry experience has led us to work with some other cloud providers, as well as catering for a local cloud solution for those who do not wish the data to be off-premise.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(ManagesServicesAboutTextSection);