import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllFourPostTypeSection from "../Componenets/ServicesAllFourPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const TestingPage = () => {

    const titleWebsite = "Testing | Xetech";
    const descriptionWebsite = "Technology is another word of the dictionary, but it has secured a place in the heart of small to large businesses. Depending on the business arena and environment, the requirements can vary.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return(
        <>
        <Header />
            <PageTitleSection
                title="A Team Of Experienced Test Professionals."
                pagetitle="Testing"
                subtitle="Our dedicated team of testers prevent bugs, reduces development costs, and improves performance"
            bgImage="assets/images/services/testing.jpg"
            />
            {/* <ServicesAboutFourColSection
            title="As your business grows, demand for bringing together the component sub-systems also grows."
            desc="We started our operations as an Integration specialist company. Whether it is an API management platform or integration with healthcare devices and machines."
            iconOne="linea-basic-map.svg"
            titleOne="Cost Reduction"
            titleOneDesc="Time is money. Reduction in time consumption during a process ultimately upshot the payback, and this is what integration undertakes."
            urlOne="#"
            iconTwo="linea-basic-map.svg"
            titleTwo="Accurate Results"
            titleTwoDesc="When data is centralised at one place, the results obtained are more precise. Accuracy often advances the execution bringing profit to a business."
            urlTwo="#"
            iconThree="linea-basic-map.svg"
            titleThree="Ameliorated Efficiency"
            titleThreeDesc="With reliable data, performance boosts and the chances of fault decreases. The time consumed in transferring data, individually, is also not an issue anymore."
            urlThree="#"
            iconFour="linea-basic-map.svg"
            titleFour="Secure Data Exchange"
            titleFourDesc="The applications can easily and securely share pertinent information with analytics systems, and deliver data-driven, contextually-relevant actions based on real-time feedback loops"
            urlFour="#"
            /> */}
            <ServicesAllFourPostTypeSection
            title="Testing Services"
                subtitle="Test your software’s usability, accessibility, compatibility and interoperability with us. We, as a state-of-art company, ensure a cutting-edge end-to-end testing solution for your enterprise. Whether you need manual, automated or semi-automated testing services, you can rely on us indisputably. We have a team of prodigy to analyse and to assure the quality of your product."
            serviceTitleOne="Functional Testing"
            serviceDescOne="For your unbeatable software, we facilitate inclusive functional specifications which also assimilates the quality analysis of the product against the expectations of the end-user. Our testing service is flexible and economical."
            serviceUrlOne="#"
            serviceImageOne="assets/images/services/functional-testing.png"
            serviceTitleTwo="Automation Testing"
            serviceDescTwo="Automation Testing is a must for a large scale and intricate project. We are here to assist you in creating an uninterrupted, speedy, flawless and productive application, close to your vision."
            serviceUrlTwo="#"
            serviceImageTwo="assets/images/services/automation-testing.png"
            serviceTitleThree="Non-Functional Testing"
            serviceDescThree="In the non-functional testing, we check the competency of the application, along with its effectiveness, usability, portability, and sustainability. Other than risk minimisation, cost reduction is also incorporated in non-functional testing."
            serviceUrlThree="#"
            serviceImageThree="assets/images/services/non-functional-testing.png"
            serviceTitleFour="Load and Performance Testing"
            serviceDescFour="With our load and performance testing, we ensure that your product and application’s performance is stable under unexpected extreme load conditions including responsiveness, scalability, reliability, stability, speed and resource usage."
            serviceUrlFour="#"
            serviceImageFour="assets/images/services/performance-testing.png"
            />
            <NewsLetterSection
            title="Let’s find out how to work together"
            subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}
export default memo(TestingPage);