import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllFivePostTypeSection from "../Componenets/ServicesAllFivePostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";


const ApplicationsPage = () => {
    const titleWebsite = "Applications | Xetech";
    const descriptionWebsite = "We specialise in developing applications for Web, Windows, Linux, Android, iOS, and complex integrations for Enterprises and problematic Startups.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);
    return (
        <>
            <Header />
            <PageTitleSection
                title="Advancing beyond your expectations"
                pagetitle="Applications"
                subtitle="Xetech helps you to simplify and modernize your enterprise application portfolio by consolidating, rationalizing, or retiring redundant, under-utilized applications in your IT landscape"
                bgImage="assets/images/technologies/application.jpg"
            />
            <AboutUsPageSection
                title=""
                subTitle="Drive Digital Transformation through App Modernization & Innovation using Xetech capabilities"
                titleOne="Custom Development"
                subtitleOne="For any field or domain, the available technical solutions for industries might not fill the functional gaps. We offer Custom Application Development to meet your demands promptly."
                altIconOne="Custom Development"
                iconOne="assets/images/svg-icons/custom-development.svg"
                titleTwo="Participation"
                subtitleTwo="We as a team can work together with your in-house team, as an extended arm, with a single aim, to give a real picture to your thoughts."
                altIconTwo="Team Work"
                iconTwo="assets/images/svg-icons/team-work.svg"
                altOne="Custom Code Development"
                imageOne="assets/images/pages/custom-code-development.jpg"
                altTwo="Participation"
                imageTwo="assets/images/pages/participation.jpg"
            />
            <ServicesAllFivePostTypeSection
                title="We dare to say that we are a top-notch software development company and, with our cost-efficient comprehensive solutions, technological support, and agile methodology, in building a seamless system, you will always be able to uphold your cutting edge."
                subtitle="We specialise in developing applications for Web, Windows, Linux, Android, iOS and complex integrations for Enterprises and problematic Start-ups. The diverse pool of experienced Xetech technological talent will produce new solutions, revolutionise your legacy system, build API gateways and middlewares. The requirement could be a cloud-based, open-source or bespoke software development, and we can ensure you on-time delivery of exceptional quality, time and again."
                serviceTitleOne="Windows Form"
                serviceDescOne="For any application, forms are the base unit. We are here to look after the function and design of windows form with easy and quick solutions. For you to add or remove or to edit data, we also create a user interface"
                serviceUrlOne="#"
                serviceImageOne="assets/images/technologies/windows-form.png"
                altOne="Windows Form"
                serviceTitleTwo="WPF"
                serviceDescTwo="To build an application that can run on the Windows operating system, we use the Windows Presentation Foundation (WPF) graphical subsystem. We have experts to create the windows client applications from simple to complex and for small to large scale businesses."
                serviceUrlTwo="#"
                serviceImageTwo="assets/images/technologies/wpf.png"
                altTwo="WPF"
                serviceTitleThree="Windows Store"
                serviceDescThree="As has been noted, .NET Core is one of the most versatile application development frameworks available and keeping that in mind, .Net Core is a platform of choice for all Xetech development. All of our inhouse products are based on .Net Core using a microservice architecture; therefore, expect nothing less than outstanding from our team."
                serviceUrlThree="#"
                serviceImageThree="assets/images/technologies/windows-store.png"
                altThree="Windows Store"
                serviceTitleFour="Linux"
                serviceDescFour="Do you want to build an application for the Linux operating system? Then you are undoubtedly at the right place. Linux is gathering more popularity among the new generation users. And our technology specialists realise it very well. Let’s grow with Linux technology."
                serviceUrlFour="#"
                serviceImageFour="assets/images/technologies/linux.png"
                alt="Linux"
                serviceTitleFive="Mobile"
                serviceDescFive="Mobilisation is the only solution to add liquidity to an immobile operation. The emergence of mobile technology leads us towards an extensive reach to the target audience. Augment the profits of your enterprise with the inimitable mobile technology solutions offered by us."
                serviceUrlFive="#"
                serviceImageFive="assets/images/technologies/mobile.png"
                altFive="Mobile"
                serviceTitleSix="Pharmacy Integration"
                serviceDescSix="The lab results accumulated in one place can be shared anywhere with ease. Automate your lab data and systems and make it valuable with the lab integration service offered by our esteemed professionals."
                serviceUrlSix="#"
                serviceImageSix="assets/images/technologies/windows-form.png"
                altSix="Windows Form"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(ApplicationsPage);