import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import FooterSection from "../Componenets/FooterSection";
import BlogPageSection from "../Componenets/BlogPageSection";
import CTAVideoSection from "../Componenets/CTAVideoSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";
import WorkImportantImageSection from "../Componenets/WorkImportantImageSection";
import WorkFeatureDescSection from "../Componenets/WorkFeatureDescSection";
import IconWorkCareHubSection from '../Componenets/IconWorkCareHubSection';


const WorkPage = () => {

    const titleWebsite = "Work | Xetech";
    const descriptionWebsite = "We have been a healthcare company from the day we started. Delivered many healthcare solutions including HIMS, LIMS, PACS, RIS, EMR, RCM and with that in mind, we have launched our next generation healthcare solution called XeHealth. XeHealth is a market-leading next-generation health information management system (HIMS) developed by Xetech to help healthcare providers deliver optimum multi-disciplinary care to their patients in multi-speciality hospitals and clinics.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitleSection
                title="Multi-specialty  electronic medical record system"
                subtitle="Next-generation Health Information Management System (HIMS) designed for use at the point of patient care in an integrated and paperless environment"
                pagetitle="Products"
                bgImage="assets/images/pages/work.png"
                /*LogoImage="assets/images/logo/xetech-health.svg"*/
            />
            <WorkImportantImageSection
                altOne="XeHealth"
                img="assets/images/projects/product-main.png"
                iconOne="assets/images/svg-icons/software-development.svg"
                titleOne="On-premise Solution"
                iconTwo="assets/images/svg-icons/cloud-solutions.svg"
                titleTwo="Software-as-a-service (SaaS) Solution"
                iconThree="assets/images/svg-icons/business.svg"
                titleThree="Cloud-hosted Solution"
            />
            <WorkFeatureDescSection
                bgImage="assets/images/pages/improve-one-bg.jpg"
                imageOne="assets/images/pages/imp-feature-image.png"
            />
            {/* <PortfolioSection /> */}
            <BlogPageSection
                title="XeHealth EMR and all it sub-modules can be deployed using one of the following methods"
                altOne="Pharmacy Management System"
                imageOne="assets/images/projects/1.png"
                titleOne="Pharmacy Management System"
                descOne="XeHealth features advanced solutions for both inpatient and outpatient pharmacy. Not only it facilitates all aspects of hospital pharmacy management, but it also integrates with third-party EMR’s and HIMS using both HL7 and FHIR. Allowing you to track pharmacy dispensing, inventory, claims and invoicing management from within a single system"
                altTwo="Radiology information system"
                imageTwo="assets/images/projects/2.png"
                titleTwo="Radiology information system"
                descTwo="XeHealth’s innovative medical imaging solutions with direct Dicom engine and integration features with PACS allows sophisticated radiology solutions for healthcare providers. The system has built-in features for order management and patient queuing with capabilities to integrate with other radiology system and solutions."
                altThree="Integration with Third-Party Software/Systems"
                imageThree="assets/images/projects/3.png"
                titleThree="Integration with Third-Party Software/Systems"
                descThree="Integration with third-party ERP/finance system for invoicing, payments and stock management Interface with external pathology laboratory information system and EHR HL7, FHIR, XML, REST, Integration Endpoints"
                altFour="Revenue Claims management System"
                imageFour="assets/images/projects/4.png"
                titleFour="Revenue Claims management System"
                descFour="XeHealth provides integrated revenue cycle solutions using future-oriented claims and transaction processing system that advances clinic operations and workflow. Integration with payer and government systems directly, the system eliminates dependency on third-party interfaces. A single point of accountability including prior auth, auto invoice, claims and payments."
                altFive="Laboratory Information system with device integration"
                imageFive="assets/images/projects/5.png"
                titleFive="Laboratory Information system with device integration"
                descFive="XeHealth LIMS has advanced capability to integrate with medical devices with a specific module for Device QC. An essential for any laboratory wanting to automate their lab systems. Microservices hybrid architecture not only allows connections with medical devices but also connects distributed labs into one solution with the added benefit of the patient portal and mobile app."
                altSix="Stock and consumable management system"
                imageSix="assets/images/projects/6.png"
                titleSix="Stock and consumable management system"
                descSix="Although our inventory management system is designed around healthcare products, it features API endpoints to integrate with Microsoft Dynamics, Business Central, Zoho, or any other cloud-based ERP. Empowering you to make the best use of your legacy system but also enabling you to slowly migrate to the modern state of the art XeHealth inventory management system."
                altSeven="Theatre Management System"
                imageSeven="assets/images/projects/7.png"
                titleSeven="Theatre Management System"
                descSeven="Streamlining workflows and revolutionising surgical events, XeHealth’s Theatre Management advances in surgical and anaesthetic procedures reducing significant risks for patients during operations and adverse events. The system further features comprehensive resource management, scheduling, Clinical Orders, Care team, Surgical Consumables/ Disposables and diagnostic device integration out of the box."
                altEight="Ward/Bed management System"
                imageEight="assets/images/projects/8.png"
                titleEight="Ward/Bed management System"
                descEight="The performance of most hospital departments is dependent on the way beds are being managed. XeHealth’s ward and bed management module exceeds all expectation. Benefiting the surgical intensive care unit, medical care unit, daycares and integration with bedside terminal and devices. From admissions to discharges, the system helps healthcare administrator to gather accurate data to identify the availability of beds across wards, empowering healthcare providers to improve overall operational efficiency and enhance each patient’s experience."
            />
            <IconWorkCareHubSection
                iconOne="assets/images/workicons/opthalmologist.png"
                titleOne="Ophthalmology Management"
                iconTwo="assets/images/workicons/diabetes.png"
                titleTwo="Diabetes & Endocrinology Management"
                iconThree="assets/images/workicons/cardiologist.png"
                titleThree="Cardiology Management"
                iconFour="assets/images/workicons/rheumatology.png"
                titleFour="Rheumatology Management"
                iconFive="assets/images/workicons/neurologies.png"
                titleFive="Neurology Management"
                iconSix="assets/images/workicons/oncologist.png"
                titleSix="Oncology Management"
                iconSeven="assets/images/workicons/paeditrician.png"
                titleSeven="Paediatrics Management"
                iconEight="assets/images/workicons/surgeon.png"
                titleEight="Surgery & Theatre Management"
                iconNine="assets/images/workicons/hematology.png"
                titleNine="Haematology Management"
                iconTen="assets/images/workicons/viral.png"
                titleTen="Viral Morphology"
                iconEleven="assets/images/workicons/infectious.png"
                titleEleven="Infectious Disease Management (i.e. Covid-19)"
                iconTweleve="assets/images/workicons/speciality.png"
                titleTweleve="Other Custom Speciality Modules"
                iconThirteen="assets/images/workicons/dentist.png"
                titleThirteen="Dental Management"
                iconFourteen="assets/images/workicons/pregnancy.png"
                titleFourteen="Pregnancy Management & Childbirth"
                iconFifteen="assets/images/workicons/immunology.png"
                titleFifteen="Immunopathology Management"
                iconSixteen="assets/images/workicons/nurse.png"
                titleSixteen="Community Nurse"
            />
          
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(WorkPage);