import React, { memo } from "react";

const ServicesAllFivePostTypeSection = (props) => {
    return (
        <>
            {/* <!-- Blog Section Start --> */}
            <div className="section b-100">
                <div className="container">
                    {/* <!-- Section Title Start --> */}
                    <div className="section-title text-center"
                    // data-aos="fade-up"
                    >
                        <h2 className="title">{props.title}</h2>
                        <p className="sub-title title-w-90">{props.subtitle}</p>
                    </div>
                    {/* <!-- Section Title End --> */}

                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                        <div className="col">
                            {/* <!-- Single Blog Start --> */}
                            <div className="blog">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.serviceImageOne} alt={props.altOne} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.serviceTitleOne}</h3>
                                    <p>
                                        {props.serviceDescOne}
                                    </p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}
                        </div>
                        <div className="col">
                            {/* <!-- Single Blog Start --> */}
                            <div className="blog">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.serviceImageTwo} alt={props.altTwo} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.serviceTitleTwo}</h3>
                                    <p>
                                        {props.serviceDescTwo}
                                    </p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}
                        </div>
                        <div className="col">
                            {/* <!-- Single Blog Start --> */}
                            <div className="blog">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.serviceImageThree} alt={props.altThree} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.serviceTitleThree}</h3>
                                    <p>
                                        {props.serviceDescThree}
                                    </p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}
                        </div>
                    </div>
                    <div className="row row-cols-lg-3 mt-10 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                        <div className="col">
                            {/* <!-- Single Blog Start --> */}
                            <div className="blog">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.serviceImageFour} alt={props.altFour} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.serviceTitleFour}</h3>
                                    <p>
                                        {props.serviceDescFour}
                                    </p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}
                        </div>
                        <div className="col">
                            {/* <!-- Single Blog Start --> */}
                            <div className="blog">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.serviceImageFive} alt={props.altFive} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.serviceTitleFive}</h3>
                                    <p>
                                        {props.serviceDescFive}
                                    </p>
                                </div>
                            </div>
                            {/* <!-- Single Blog End --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Blog Section End --> */}
        </>
    )
}

export default memo(ServicesAllFivePostTypeSection);