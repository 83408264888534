import React, { memo } from 'react';

const WorkImportantImageSection = (props) => {
    return (
        <>
            <div className="section mt-10 mb-10">
                <div className="container">
                    <div className="row icon-size-redefine">
                        <div className="col-md-12 text-center mb-4">
                            <img src={process.env.PUBLIC_URL + 'assets/images/logo/xetech-health.svg'} alt={props.title} className="productLogoXeHealth" />
                        </div>
                        <div className="col-md-12">
                            <p className="mb-5 text-justify text-medium font-size-9">
                                XeHealth is a market-leading next-generation health information management system (HIMS) developed by Xetech to help healthcare providers deliver optimum multi-disciplinary care to their patients in multi-speciality hospitals and clinics. XeHealth core features are designed to improve the efficiency, cost-effectiveness, quality, and safety of health care delivery with built-in decision support systems convert data to clinically relevant information and present it in the actionable form to healthcare providers and doctors.
                            </p>
                        </div>

                        <div className="col-md-12 mb-10">
                            <img src={`${process.env.PUBLIC_URL + props.img}`} alt={props.altOne} className="img-important-res" />
                        </div>
                        {/* <!-- Contact Information Start --> */}
                        <div className="col-md-4">
                            <div className="contact-info" data-vivus-hover>
                                {/*<div className="icon">*/}
                                {/*    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt="Xetech" />*/}
                                {/*</div>*/}
                                <div className="info">
                                    <h4 className="title">{props.titleOne}</h4>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                        {/* <!-- Contact Information Start --> */}
                        <div className="col-md-4">
                            <div className="contact-info" data-vivus-hover>
                                {/*<div className="icon">*/}
                                {/*    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt="Xetech" />*/}
                                {/*</div>*/}
                                <div className="info">
                                    <h4 className="title">{props.titleTwo}</h4>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                        {/* <!-- Contact Information Start --> */}
                        <div className="col-md-4">
                            <div className="contact-info" data-vivus-hover>
                                {/*<div className="icon">*/}
                                {/*    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt="Xetech" />*/}
                                {/*</div>*/}
                                <div className="info">
                                    <h4 className="title">{props.titleThree}</h4>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(WorkImportantImageSection);