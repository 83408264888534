import React, { memo } from "react";

const OurAccreditationSection = () => {
    return (
        <>
            <div className="section mt-5 pb-10 pt-10 mb-0 position-relative " style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/images/bg/accertationsBG.jpg'})` }} data-bg-image={require('../assets/images/bg/accertationsBG.jpg')}>
                <div className="overlayBGAccreditation">
                </div>
                <div className="container shape-animate slider-brand-all overflow-hidden">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center">
                                <h2 className="title text-blue-theme">Our Accreditation</h2>
                            </div>
                            <div className="ourAccertationBoxBorder">
                                <div className="ourAccertationBoxBorderLine">
                                    <div className="ourAccertationBoxBorderCircle">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                                <img src={process.env.PUBLIC_URL + '../assets/images/AccreditationIcons/iso-qm.png'} alt="ISO Quality Management" />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                                <img src={process.env.PUBLIC_URL + '../assets/images/AccreditationIcons/microsoft-gold-partner.png'} alt="Microsoft gold Partner" />
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                                <img src={process.env.PUBLIC_URL + '../assets/images/AccreditationIcons/iso-ism.png'} alt="ISO Information and security management" />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                                <img src={process.env.PUBLIC_URL + '../assets/images/AccreditationIcons/microsoft-cloud-partner.png'} alt="microsoft Cloud Partner" />
                            </div>
                        </div>
                        <div className="col-md-2 col-6">
                            <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                                <img src={process.env.PUBLIC_URL + '../assets/images/AccreditationIcons/iso-it.png'} alt="ISO Information and technology" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(OurAccreditationSection);