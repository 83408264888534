import React, { memo } from "react";

const MapContactUsSection = () =>{
    return(
        <>
            <div className="row">
                <div className="col-md-4">
                    {/* <!--Google Map Area Start--> */}
                    <div className="google-map-area section text-center">
                        <div className="container">
                            <div className="contact-map-area">
                                <iframe className="contact-map" title="124 City Road, London, EC1V 2NX" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2354166451114!2d-0.09124812312005828!3d51.527241809253404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ca671a415eb%3A0xba3bb840e2e16b33!2s124%20City%20Rd%2C%20London%20EC1V%202NX%2C%20UK!5e0!3m2!1sen!2sin!4v1705500006218!5m2!1sen!2sin" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <!--Google Map Area Start--> */}
                </div>
                <div className="col-md-4">
                    {/* <!--Google Map Area Start--> */}
                    <div className="google-map-area section text-center">
                        <div className="container">
                            <div className="contact-map-area">
                                <iframe className="contact-map" title="Downtown San Jose, 99 Almaden Blvd Suite 600, San Jose, CA, 95113" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.434264589895!2d-121.89664798441635!3d37.332226445269285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcca46c6f87e1%3A0x40f2fbacf3de12e!2s99%20Almaden%20Blvd%20%23600%2C%20San%20Jose%2C%20CA%2095113%2C%20USA!5e0!3m2!1sen!2sin!4v1642054688010!5m2!1sen!2sin" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <!--Google Map Area Start--> */}
                </div>
                <div className="col-md-4">
                    {/* <!--Google Map Area Start--> */}
                    <div className="google-map-area section text-center">
                        <div className="container">
                            <div className="contact-map-area">
                                <iframe className="contact-map" title="C-56/32, Sector 62, Noida, 201301 Ph: +91 120 3117870" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.495563144697!2d77.36452231547885!3d28.614906091628537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce506d38653bd%3A0x570ceb1d28504c5!2sXetech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1661413010240!5m2!1sen!2sin" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <!--Google Map Area Start--> */}
                </div>
                <div className="col-md-3 d-none">
                    {/* <!--Google Map Area Start--> */}
                    <div className="google-map-area section text-center">
                        <div className="container">
                            <div className="contact-map-area">
                                <iframe className="contact-map" title="SHAMS Sharjah Media City Free Zone, Sharjah

" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.7657207401576!2d55.67610881538191!3d25.278465334601073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5929a229f1c75%3A0xb28ae30eb6bb813a!2sSharjah%20Media%20City%20(Shams)!5e0!3m2!1sen!2sin!4v1642080577521!5m2!1sen!2sin" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <!--Google Map Area Start--> */}
                </div>
            </div>
        
        </>
    )
}

export default memo(MapContactUsSection);