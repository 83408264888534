import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllPostTypeSection from "../Componenets/ServicesAllPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const CloudServicesPage = () => {

    const titleWebsite = "Cloud Service | Xetech";
    const descriptionWebsite = "Xetech provides managed cloud services, including migration, application development, maximising cloud efficiency and reducing your IT infrastructure cost. As a cloud solution provider, we would commit to Cost Savings, High Availability, Reliability, Performance and Disaster Recovery with 365 days 24x7 support. ";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitleSection
                title="Get an array of capabilities for your business, with our cloud services."
                pagetitle="Cloud Services"
                subtitle="Streamline with minimal operational burdens, improve service levels, reduce costs, and focus on higher-level business activities with a proven model of Cloud services"
                bgImage="assets/images/technologies/cloud-services.jpg"
            />
            <AboutUsPageSection
                title=""
                subTitle="Mitigate risk by leveraging economies of scale and our global workforce for faster cloud innovation"
                titleOne="Integration Services"
                subtitleOne="For on-premise to cloud, cloud to on-premise and cloud to cloud scenarios, we offer Cloud Integration Services for the application, as per your long-term business goals."
                titleTwo="Implementation Services"
                subtitleTwo="We offer Cloud Migration, Implementation and Management Services to assist you in migration, implementation, management and monitoring of your epoch-making applications on the cloud ecosystem."
                altIconOne="Integration Services"
                iconOne="assets/images/svg-icons/integration-services.svg"
                altIconTwo="Implement Services"
                iconTwo="assets/images/svg-icons/implement-services.svg"
                altOne="Integrations"
                imageOne="assets/images/pages/integrations.jpg"
                altTwo="Software Implement"
                imageTwo="assets/images/pages/software-implement.jpg"
            />
            <ServicesAllPostTypeSection
                ptop="0"
                title="Learn how to leverage the benefits of cloud technology with our expertise and years of experience in Cloud Application Development service that includes various latest technologies, Microsoft Azure, Amazon Web Services, Office365, and many more."
                subtitle="The cloud services in themselves are a state of the art technology and offer various benefits from small-scale to leading companies. You can also let your organization benefit, when we are here to assist you in getting most out of the power of the Cloud Services in countless ways, incorporating mobile backend, intelligence, and performance optimization. For Cloud storage, data migration, data delivery, and mobile and web applications, our experts cater to storage services to our clients. We are proficient and unstoppable, have immense pride in our skills and are evenly acquainted with broadened carte blanche to research and learning."
                serviceTitleOne="Microsoft Azure"
                serviceDescOne="To create and test an application or to deploy and manage them, Microsoft Azure or Windows Azure can serve as a bonanza for a business with its immeasurable potential. For the augmentation of an enterprise, leverage the gains of IaaS, PaaS, and SaaS and other frameworks and tools."
                serviceUrlOne="#"
                altOne="Microsoft Azure"
                serviceImageOne="assets/images/technologies/microsoft-azure.png"
                serviceTitleTwo="Amazon Web Server (AWS)"
                serviceDescTwo="Whether you are an organization or an individual, you can take the benefits of the mind-boggling utility of virtual system clusters rendered by the Amazon Web Servers (AWS). The implications of our proficient AWS experts’ services are at par the quality standards."
                serviceUrlTwo="#"
                altTwo="AWS"
                serviceImageTwo="assets/images/technologies/aws.png"
                serviceTitleThree="Office 365"
                serviceDescThree="With Office 365 subscription-based service you can work from anywhere with what you know. Its reliability and security features along with mix and match plans, are add-ons to the advantages it offers with an undemanding collaboration of the team and its users."
                serviceUrlThree="#"
                altThree="Office 365"
                serviceImageThree="assets/images/technologies/office-365.png"
                serviceTitleFour="Business Central"
                serviceDescFour="With no installation cost and agile technology, the Business Central cloud vouchsafe a lower time and cost requisite. Easily track your service tasks, workloads, and employee skills with the Business Central cloud configurable dashboards and multidimensional reports and accelerate your productivity."
                serviceUrlFour="#"
                altFour="Business Central"
                serviceImageFour="assets/images/technologies/business-central.png"
                serviceTitleFive="Facebook Apps"
                serviceDescFive="The enterprises are looking for an engaging app for promotion to connect, publicize, and share information with global users. Facebook app development service is thus gaining popularity in the current time. Get a smart business marketing strategy and enhance your business growth with our experts."
                serviceUrlFive="#"
                altFive="Facebook Apps"
                serviceImageFive="assets/images/technologies/facebook-apps.png"
                serviceTitleSix="Google Apps"
                serviceDescSix="With the utility of Google Apps or G Suite, the businesses are amplifying their productivity, reforming their strategies, and minimizing the timeframe for project completion. Our specialists are here to assist you in getting the maximum benefits of Google apps."
                serviceUrlSix="#"
                altSix="Google Apps"
                serviceImageSix="assets/images/technologies/google-apps.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(CloudServicesPage);