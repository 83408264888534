import React, { memo } from "react";
import Slider from "react-slick";

const BrandLogoSliderSection = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 200,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>


            <div className="section mt-0" style={{ backgroundColor: '#f8faff' }} >
                <div className="container-fluid shape-animate slider-brand-all overflow-hidden">
                    <Slider {...settings}>
                        {props.urlOne ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlOne}`} alt={props.altOne} />
                        </div> : ''}
                        {props.urlTwo ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlTwo}`} alt={props.altTwo} />
                        </div> : ''}
                        {props.urlThree ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlThree}`} alt={props.altThree} />
                        </div> : ''}

                        {props.urlFour ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlFour}`} alt={props.altFour} />
                        </div> : '' }
                        {props.urlFive ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlFive}`} alt={props.altFive} />
                        </div> : '' }
                        {props.urlSix ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlSix}`} alt={props.altSix} />
                        </div> : '' }
                        {props.urlSeven ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlSeven}`} alt={props.altSeven} />
                        </div> : '' }
                        {props.urlEight ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlEight}`} alt={props.altEight} />
                        </div> : '' }
                        {props.urlNine ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlNine}`} alt={props.altNine} />
                        </div> : '' }
                        {props.urlTen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlTen}`} alt={props.altTen} />
                        </div> : '' }
                        {props.urlEleven ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlEleven}`} alt={props.altEleven} />
                        </div> : '' }
                        {props.urlTweleve ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlTweleve}`} alt={props.altTweleve} />
                        </div> : '' }
                        {props.urlThirteen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlThirteen}`} alt={props.altThirteen} />
                        </div> : '' }
                        {props.urlFourteen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlFourteen}`} alt={props.altFourteen} />
                        </div> : '' }
                        {props.urlFifteen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlFifteen}`} alt={props.altFifteen} />
                        </div> : ''}
                        {props.urlSixteen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlSixteen}`} alt={props.altSixteen} />
                        </div> : ''}
                        {props.urlSeventeen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlSeventeen}`} alt={props.altSeventeen} />
                        </div> : '' }
                        {props.urlEighteen ? <div className="w-100 text-center mt-4 mb-4 slider-brand-all-img">
                            <img src={`${process.env.PUBLIC_URL + props.urlEighteen}`} alt={props.altEighteen} />
                        </div> : ''}


                    </Slider>
                </div>
            </div>
        </>
    )
}

export default memo(BrandLogoSliderSection);