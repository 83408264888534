import { React, useEffect } from 'react';
import FooterSection from "../Componenets/FooterSection";
import Header from '../Componenets/Header';
import PageCareerNewDesign from '../Componenets/PageCareerNewDesign';
import CarrerInnserDataSection from '../Componenets/CarrerInnserDataSection';

const CareerInnerPage = ({ props, match}) => {
     useEffect(() => {
        window.scrollTo(0, 0)

     }, []);
    return (
        <>
            <Header page="career-details" />
            <PageCareerNewDesign
                pagetitle="Integration"
                bgImage="assets/images/pages/career-inner.jpg"
                title=" Join Our Team"
                desc="More than a job! It’s an adventure in innovation where you’ll gain vast experiences and skills. You will work on enterprise solutions where you should be able to design, build and test high-quality software solutions"
            />
            <CarrerInnserDataSection  />
            <FooterSection />
        </>
    )
}

export default CareerInnerPage;