import React, { memo } from "react";
import { Link } from "react-router-dom";

const ServiceSection = (props) => {
    return (
        <>
            {/* {/* <!-- Service Section Start --> */}
            <div className="section section-padding-t90-b100" data-bg-color="#f8faff" style={{ backgroundColor: `#f8faff` }}>
                <div className="container">

                    {/* <!-- Section Title Start --> */}
                    <div className="section-title text-center mb-12"
                    // data-aos="fade-up"
                    >
                        <h2 className="title">{props.title}</h2>
                        <p className="pl-5 pr-5 mt-4 w-100">{props.subtitle}</p>
                    </div>
                    {/* <!-- Section Title End --> */}

                    <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 story-icon-redefined">

                        {/* <!-- Icon Box Start --> */}
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >

                            <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt={props.altIconOne} />
                                </div>
                                <div className="content">
                                    <h3 className="title">Result-driven services</h3>
                                    <div className="desc">
                                        <p>Our competent team is highly driven by enthusiasm, experience and expertise to bring new ideas to meet the challenging demands of your software development project.</p>
                                    </div>
                                    <Link to={props.urlOne} className="link">Learn More</Link>
                                </div>
                            </div>

                        </div>
                        {/* <!-- Icon Box End --> */}

                        {/* <!-- Icon Box Start --> */}
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >

                            <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt={props.altIconTwo} />

                                </div>
                                <div className="content">
                                    <h3 className="title">Tailor-made services</h3>
                                    <div className="desc">
                                        <p>We believe in supporting our clients with top-notch flexible business solutions by scaling according to their requirements; be it all or any part of the software development flow-cycle.</p>
                                    </div>
                                    <Link to={props.urlTwo} className="link">Learn More</Link>
                                </div>
                            </div>

                        </div>
                        {/* <!-- Icon Box End --> */}

                        {/* <!-- Icon Box Start --> */}
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >

                            <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt={props.altIconThree} />

                                </div>
                                <div className="content">
                                    <h3 className="title">Enhanced work efficiency</h3>
                                    <div className="desc">
                                        <p>Our award-winning talent-oriented workforce focuses on imbibing the latest technologies in your business with software automation to boost up productivity and efficiency.

                                        </p>
                                    </div>
                                    <Link to={props.urlThree} className="link">Learn More</Link>
                                </div>
                            </div>

                        </div>
                        {/* <!-- Icon Box End --> */}

                        {/* <!-- Icon Box Start --> */}
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >

                            <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconFour}`} alt={props.altIconFour} />

                                </div>
                                <div className="content">
                                    <h3 className="title">More than just a company</h3>
                                    <div className="desc">
                                        <p>We at XeTech, believe in trust, transparency, cooperation and collaboration. We are a legally established, ISO certified company and will be your best-compatible digital transformation partner.

                                        </p>
                                    </div>
                                    <Link to={props.urlFour} className="link">Learn More</Link>
                                </div>
                            </div>

                        </div>
                        {/* <!-- Icon Box End --> */}

                    </div>

                </div>
            </div>
            {/* <!-- Service Section End --> */}
        </>
    )
}


export default memo(ServiceSection);