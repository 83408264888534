import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import FooterSection from "../Componenets/FooterSection";
import NewContactUsDesignSection from "../Componenets/NewContactUsDesignSection";
import MapContactUsSection from "../Componenets/MapContactUsSection";

const ContactUsPage = () => {

    const titleWebsite = "Contact Us | Xetech";
    const descriptionWebsite = "Xetech delivers measurable business value through process automation. Let us know your technical challenges, and our experts will help you resolve them. In addition, we provide solutions for patient and clinical care, telemedicine and remote patient care,  banking, Azure, AWS, and more.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Contact Us"
                pagetitle="Contact Us"
                bgImage="assets/images/pages/contact-us-new.jpg"
            />
            <NewContactUsDesignSection
                contactUsHeadingForm="Get In Touch"
                addressOne="124 City Road, London, EC1V 2NX"
                addressTwo="Downtown San Jose,
            99 Almaden Blvd Suite 600,
            San Jose, CA,
            95113"
                addressThree="C-56/32, Sector 62, Noida, 201301"
//                addressFour="SHAMS
//Sharjah Media City Free Zone,
//Sharjah"
                phoneOne="UK:(+44) 20 8080 9601"
                phoneTwo="US:(+1) 669 244 0488"
                phoneThree="IN:(+91) 120 3512812"
                emailOne="contact@xetech.com"
                emailTwo=""
                emailThree=""
            />
            <MapContactUsSection />
            <FooterSection />
        </>
    )
}

export default memo(ContactUsPage);