import React from 'react';
import { Link } from 'react-router-dom';
import SliderMain from "react-slick";
import DataCareer from '../Jobs/Alljobs';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    // responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         infinite: true,
    //         dots: true
    //       }
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2,
    //         initialSlide: 2
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1
    //       }
    //     }
    //   ]
}

const PageTitleCareerSection = () => {

    var ListJobs = DataCareer.map((data, key) => {
        return (
            <div className="col mb-6">
                {/* <!-- Contact Information Start --> */}
                <div className="contact-info career-conatct-info" data-vivus-hover>
                    <div className="info">
                        <Link to={{
                            pathname: '/career-details/' + data.key,
                            state: data.key
                        }}state="reactnative"  > <h2 className="career-text-bolder text-left">{data.title}</h2> </Link>
                    </div>
                </div>
            </div>

        )


        /*return <li> {data.key} </li>*/
    });
    return (
        <>
            <section className="section-padding section" style={{ backgroundColor: '#FFF' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-12 pb-5 d-flex flex-column justify-content-center align-items-start">
                            <h1 className="careerPageHeading">
                                Find the Careers you <span className="spanHeadingDesign">Deserve</span>
                            </h1>
                            <p className="pt-4">
                                The Xetech group of companies has been established for over decades with offices in the UK, the Middle East and India, supporting over 250 different software solutions worldwide.
                            </p>
                        </div>
                        <div className="col-md-8 col-12 overflow-hidden intro-slider-wrap">
                            <div className="slider-career">
                                <SliderMain {...settings}>
                                    <img src="assets/images/career-images/career-bg-1.jpg" alt="Career Image" />
                                    <img src="assets/images/career-images/career-bg-2.png" alt="Career Image" />
                                    <img src="assets/images/career-images/career-bg-3.png" alt="Career Image" />
                                    <img src="assets/images/career-images/career-bg-4.jpg" alt="Career Image" />
                                </SliderMain>
                                {/*<img src="assets/images/hero-image/hero-1.jpg" alt="Career Image" />*/}
                            </div>
                        </div>
                    </div>
                </div>


                <section className="section pt-15 pb-15" style={{ backgroundColor: 'rgb(3, 14, 34)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-white">Our mission is to</h3>
                                <ul className="text-white listMissionCareer ml-0 pl-0 mt-4">
                                    <li>Design and develop high-quality software that meets the needs of our customers.</li>
                                    <li>Deliver systems on-time and within budget.</li>
                                    <li>Expand our services to new customers in different sectors.</li>
                                    <li>Create long-term relationships with our customers that encourages repeat business.</li>
                                    <li>Develop a sustainable and profitable business that provides an interesting, challenging and rewarding working environment for our staff. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row mt-15 pt-10 pl-5 pr-5" style={{ backgroundColor: '#f2f2f2' }}>
                            <div className="col-md-12">
                                <div className="section-title text-center mb-lg-12 mb-sm-12 mb-xs-12"
                                // data-aos="fade-up"
                                >
                                    <h2 className="title">Latest Xetech jobs</h2>
                                    <p className="pt-5">We are a forward-looking company and pride ourselves on the quality of the service we provide to our customers and we achieve high standards through our dedication to each customer and our flexible approach to business.  We have a friendly and innovative approach to business, underpinned by extensive experience in the delivery of high-quality software solutions in a number of diverse market sectors.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-lg-4 pb-10 pl-5 pr-5 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 icon-size-redefine" style={{ backgroundColor: '#f2f2f2' }}>
                            {ListJobs}
                        </div>
                    </div>
                </section>

            </section>



            <section className="section d-none" style={{ backgroundColor: '#fff' }}>
                <div className="container-fluid pr-0">
                    <div className="row">
                        <div className="col-md-6 d-flex flex-column justify-content-center align-items-start p-10">
                            <h1 className="careerPageHeading">
                                Get ready to start your <span className="spanHeadingDesign">Xetech Journey</span> .
                            </h1>

                            <ul className="mt-5 careerListJobs ">
                                {ListJobs}
                            </ul>

                        </div>
                        <div className="col-md-6">
                            <img src="assets/images/hero-image/hero-1.jpg" alt="Career Image" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default PageTitleCareerSection;