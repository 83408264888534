import React, { Component } from 'react';
import { Route } from 'react-router';
import HomePage from './Pages/HomePage';
import AboutUsPage from './Pages/AboutUsPage';
import ServicesPages from './Pages/ServicesPages';
import SoftwareConsultingPage from './Pages/SoftwareConsultingPage';
import IntegrationPage from './Pages/IntegrationPage';
import TechnologySpecialists from './Pages/TechnologySpecialistsPage';
import WorkPage from './Pages/WorkPage';
import ContactUsPage from './Pages/ContactUsPage';
import TestingPage from './Pages/TestingPage';
import TechnologyPage from './Pages/TechnologyPage'
import ApplicationsPage from './Pages/ApplicationsPage';
import BrowserApplicationsPage from './Pages/BrowserApplicationsPage';
import CloudServicesPage from './Pages/CloudServicesPage';
import MobileDevelopmentPage from './Pages/MobileDevelopmentPage';
import ProgrammingExpertisePage from './Pages/ProgrammingExpertisePage';
import DatabasePage from './Pages/DatabasePage';
import ManagesServicesPage from './Pages/ManagesServicesPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import CareerPage from './Pages/CareerPage';
import CareerInnerPage from './Pages/CareerInnerPage';
import CookieConsent from "react-cookie-consent";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button"; //Add this line Here
import Home from './Pages/Dashboard/Home';
import Banners from './Pages/Dashboard/Banners';
/*import './custom.css'*/


export default class App extends Component {
  static displayName = App.name;
  render () {
    return (
        <>
            
            {/*<Route exact path='/' component={Home} />*/}
            <Route exact path='/' component={HomePage} />
            <Route path='/about' component={AboutUsPage} />
            <Route path='/services' component={ServicesPages} />
            <Route path='/softwareconsulting' component={SoftwareConsultingPage} />
            <Route path='/integration' component={IntegrationPage} />
            <Route path='/technologyspecialists' component={TechnologySpecialists} />
            <Route path='/testing' component={TestingPage} />
            <Route path='/applications' component={ApplicationsPage} />
            <Route path='/browserapplications' component={BrowserApplicationsPage} />
            <Route path='/cloudservices' component={CloudServicesPage} />
            <Route path='/technology' component={TechnologyPage} />
            <Route path='/mobiledevelopment' component={MobileDevelopmentPage} />
            <Route path='/programmingexpertise' component={ProgrammingExpertisePage} />
            <Route path='/database' component={DatabasePage} />
            <Route path='/work' component={WorkPage} />
            <Route path='/contact' component={ContactUsPage} />
            <Route path='/managesservices' component={ManagesServicesPage} />
            <Route path='/privacy-policy' component={PrivacyPolicyPage} />
            <Route path='/career' component={CareerPage} />
            <Route path='/career-details/:key' component={CareerInnerPage} />



            <Route path='/dashboard' component={Home} />
            <Route path='/dashboardbanners' component={Banners} />



            {/*<Route path="" component={My404Component} />*/}
            {/*<Route> <My404Component />  </Route>*/}
            
            <ScrollUpButton
                StopPosition={0}
                ShowAtPosition={150}
                EasingType='easeOutCubic'
                AnimationDuration={500}
                ContainerClassName='ScrollUpButton__Container'
                TransitionClassName='ScrollUpButton__Toggled'
                style={{}}
                ToggledStyle={{}}
            >
                go
            </ScrollUpButton>
            {/*<Route path='/counter' component={Counter} />*/}
            {/*<Route path='/fetch-data' component={FetchData} />*/}
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Privacy Policy"
                enableDeclineButton
                cookieName="myCookiesName"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "12px" }}
                expires={150}
                declineButtonStyle={{ color: "#fff", fontSize: "12px" }}
                onDecline={() => {
                    /*alert("nay!");*/
                    window.open("privacy-policy", "_self")
                }}
            >
                We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking "Accept", you consent to the use of ALL the cookies. However, you may visit "Privacy Policy" to provide a controlled consent.{" "}
                <span style={{ fontSize: "10px" }}></span>
            </CookieConsent>
        </>
    );
  }
}
