import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllPostTypeSection from "../Componenets/ServicesAllPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const MobileDevelopmentPage = () => {

    const titleWebsite = "Mobile Development | Xetech";
    const descriptionWebsite = "Smartphones have become a necessity for one and all. And with the same extent, mobile applications have taken over the market. So if you want to invest in mobile apps, it is the right time. As an app development company, we will work alongside you to develop the roadmap your app deserves from day one.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Our experts create great experiences with their faster and flawless application building skills."
                pagetitle="Mobile Development"
                subtitle="Enhance a feature-rich, scalable app in your business benefaction. Get it designed and developed by an experienced team"
                bgImage="assets/images/technologies/mobile-development.jpg"
            />
            <AboutUsPageSection
                title=""
                subTitle="Developing custom mobile apps for Android and iOS platforms for businesses of any size."
                titleOne="Application Requirements"
                subtitleOne="A mobile application should be simpler and faster to assure that the users can play with it easily. The app should be built for scale in case it gains much popularity."
                titleTwo="Application Benefits"
                subtitleTwo="With fewer limitations, a mobile app functions smoother than a mobile site. It can communicate with the other features of the phone and can also support the offline mode."
                altIconOne="Application Requirment"
                iconOne="assets/images/svg-icons/application-requirment.svg"
                altIconTwo="Application Benefit"
                iconTwo="assets/images/svg-icons/application-benefit.svg"
                altOne="Application Requirment"
                imageOne="assets/images/pages/apllication-requirment.jpg"
                altTwo="App Benefit"
                imageTwo="assets/images/pages/app-benefit.jpg"
            />
            <ServicesAllPostTypeSection
                ptop="0"
                title="Mobile applications are booming with their untold benefits that we can help you to envisage precisely. Get a strategically conceived mobile app for your business now, and you can reap massive rewards in the future."
                subtitle="Smartphones have become a basic necessity for one and all. And with the same extent, mobile applications have taken over the market. If you want to invest in mobile apps, then it is the right time. Businesses of different domains have their app to facilitate better user experience as well as boosting interactivity between them and the end-users. Our mobile development team is up to date with the latest technological advancement, along with the competitiveness prevailing in the current market. After perceiving your ideas, we imbibe it completely, and after visualising, we eliminate all the unwieldy complexity, but not your requirements."
                serviceTitleOne="Android App"
                serviceDescOne="With an open platform and voguish design, Android is contemporaneous with other technological advancements and has won millions of delighted users. With a strong foundation, a business can get more audiences resulting in unprecedented success, both in terms of sales and growth."
                serviceUrlOne="#"
                altOne="Android App"
                serviceImageOne="assets/images/technologies/andriod-app.png"
                serviceTitleTwo="iOS App"
                serviceDescTwo="Owning an iOS device gives us a sense of having an absolute masterpiece of technology on our palm. The outstanding security manoeuvres, as well as an extended feature of storing media, make iOS what it is now. We will assist you in adopting it as a money magnet."
                serviceUrlTwo="#"
                altTwo="IOS APP"
                serviceImageTwo="assets/images/technologies/ios-app.png"
                serviceTitleThree="React Native"
                serviceDescThree="React Native facilitates us to create a single coded mobile app to run on Android and iOS both, thus presenting a native-app. It is recommended for businesses to eliminate the issues faced while providing the accessibility of their portal to the users."
                serviceUrlThree="#"
                altThree="React Native"
                serviceImageThree="assets/images/technologies/react-native.png"
                serviceTitleFour="Xamarin Forms"
                serviceDescFour="Cross-platform, robust community, open-source, and integrated visual studio are the benefits offered by Xamarin. There is only one code for all platforms. Along with the feature of reusability, we aim for a cost-effective service and an excellent user experience with amazing possibilities."
                serviceUrlFour="#"
                altFour="Xamarin forms"
                serviceImageFour="assets/images/technologies/xamarin-forms.png"
                serviceTitleFive="Flutter"
                serviceDescFive="The Google flutter app development SDK being robust and reactive with widgets and tools is created to build mobile apps for Android, iOS, and Google. We offer Flutter app development services, appreciated for fast delivery, easy coding, thorough testing and satisfactory experience."
                serviceUrlFive="#"
                altFive="Flutter"
                serviceImageFive="assets/images/technologies/flutter.png"
                serviceTitleSix="PhoneGap/Cordova"
                serviceDescSix="PhoneGap is an open-source, cross-platform mobile application development framework. Along with being cost-effective, PhoneGap provides a large community base, better access to Native APIs, suppleness, flexibility, and robust backend support. To build an application using PhoneGap, you can choose us readily."
                serviceUrlSix="#"
                altSix="Phone Gap / Cordova"
                serviceImageSix="assets/images/technologies/phoneGap.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(MobileDevelopmentPage);