import React from 'react';
import { authProvider } from '../../AuthProvider';
import AzureAD from 'react-aad-msal';
import DashboardHeader from '../../Componenets/Dashboard/DashboardHeader';
import Sidebar from '../../Componenets/Dashboard/Sidebar';

export default function Banners() {
    return (
        <>
            <DashboardHeader />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <input type="file" className="form-group" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
