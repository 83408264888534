import React, { memo } from "react";

const AboutUsWhoWeAreSection = (props) =>{
    return(
        <>
        {/* {props.title} */}
        
        {/* {props.details} */}

        {/* <!-- Start Work Details --> */}
        <div className="section section-padding">
            <div className="container">
                <div className="row pt--100 pb--80">

                    {/* <!-- Portfolio Left --> */}
                    <div className="col-lg-4 col-md-6 col-12 d-flex align-items-center ">
                        <div className="work-left work-details"
                        // data-aos="fade-up"
                        >
                            <div className="portfolio-main-info">
                                <h2 className="title">{props.title}</h2>
                                {/* <!-- Start Details List --> */}
                                <div className="work-details-list mt-10">

                                    <div className="details-list contact-info text-center align-items-center justify-content-center">
                                        <label className="mb-0">Software Development</label>
                                    </div>

                                    <div className="details-list contact-info align-items-center text-center justify-content-center">
                                        <label className="mb-0">Cloud Solutions</label>
                                    </div>

                                    <div className="details-list contact-info align-items-center text-center justify-content-center">
                                        <label className="mb-0">In business since 2009</label>
                                    </div>

                                    <div className="details-list contact-info align-items-center text-center justify-content-center">
                                        <label className="mb-0">250+ Solutions</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Work Right --> */}
                    <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
                        <div className="work-left work-details mt-6">
                            <div className="work-main-info">
                                <div className="work-content">
                                    <h6 className="title font-size-lg"
                                    // data-aos="fade-up"
                                    >{props.subtitle}</h6>

                                    <div className="desc mt-8">
                                        <div className="content mb-5"
                                        // data-aos="fade-up"
                                        >
                                            {props.details}
                                        </div>

                                        <div className="work-btn">
                                        {/*<ButtonDesignSection title={props.buttontext} url={props.buttonUrl} className="btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4" style='' />*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
        {/* <!-- Start Work Details --> */}
        </>
    )
}

export default memo(AboutUsWhoWeAreSection);