import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import FooterSection from "../Componenets/FooterSection";
import FunfactSection from "../Componenets/FunfactSection";
import NewServicesSixPageSection from "../Componenets/NewServicesSixPageSection";

const TechnologyPage = () => {

    const titleWebsite = "Technology  | Xetech";
    const descriptionWebsite = "See how we help software as a Service Technology Solutions Strategies, digital platforms, experiences, insights, and other end-to-end services.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Build Your Future, today"
                pagetitle="Technology"
                subtitle="We build digital products to boost profits & efficiency"
                bgImage="assets/images/pages/technology-new.jpg"
            />
            <NewServicesSixPageSection
                title="OUR TECHNOLOGY"
                // subTitle="A solution for every complex challenge"
                imageOne="assets/images/technologies/application.jpg"
                iconOne="assets/images/svg-icons/application.svg"
                titleOne="Applications"
                urlOne="/applications"
                contentOne="Turn your ideas into reality with our innovative and state-of-art application expertise"
                imageTwo="assets/images/technologies/browser.png"
                iconTwo="assets/images/svg-icons/browser-application.svg"
                titleTwo="Browser Applications"
                urlTwo="/browserapplications"
                contentTwo="Technologize your business with our ground-breaking browser application solutions "
                imageThree="assets/images/technologies/cloud-services.jpg"
                iconThree="assets/images/svg-icons/cloud-service.svg"
                titleThree="Cloud Services"
                urlThree="/cloudservices"
                contentThree="Easily accessible, scalable and cost-effective software solutions with cloud architecture"
                imageFour="assets/images/technologies/database.jpg"
                iconFour="assets/images/svg-icons/database.svg"
                titleFour="Database"
                urlFour="/database"
                contentFour="Transform your business operations with our robust bespoke database solutions "
                imageFive="assets/images/technologies/mobile-development.jpg"
                iconFive="assets/images/svg-icons/mobile-development.svg"
                titleFive="Mobile Development"
                urlFive="/mobiledevelopment"
                contentFive="Pioneering and interactive mobile app to help your brand engagement grow "
                imageSix="assets/images/technologies/programming.jpg"
                iconSix="assets/images/svg-icons/programming-expertise.svg"
                titleSix="Programming Expertise"
                urlSix="/programmingexpertise"
                contentSix="Kickstart your business with the state-of-art programming suiting your needs "
                descMain="We strive to deliver excellence across a wide spectrum of technology. Our services comprise of software conceptualization, software architecture & design, software development, database development, cloud computing, software migration, application maintenance & testing. Our smart and enthusiastic team helps the businesses accelerate their efficiency using the latest technologies."

            />
            <FunfactSection
                heading="We help our clients solve business problems, increase
                visibility, and achieve unexpected results"
                titleOne="20+"
                subTitleOne="Year's Experience"
                titleTwo="15"
                subTitleTwo="Active Projects"
                titleThree="130"
                subTitleThree="Projects Done"
                titleFour="200+"
                subTitleFour="Satisfied Clients"
            />
            {/* <ContactSection /> */}
            <FooterSection />
        </>
    )
}

export default memo(TechnologyPage);