import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllPostTypeSection from "../Componenets/ServicesAllPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const ProgrammingExpertisePage = () => {

    const titleWebsite = "Programming Expertise | Xetech";
    const descriptionWebsite = "The specific set of actions blended with our programmer skills creates a tangible result. An absolute merging of art and science is exemplified by incorporating our ingenuity with technical and analytical elements.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Get our services to ensure consistent growth and development for your business."
                pagetitle="Programming Expertise"
                subtitle="Kickstart your business with the state-of-art programming suiting your needs "
                bgImage="assets/images/technologies/programming.jpg"
            />
            <AboutUsPageSection
                title=""
                subTitle="Choose the right tech stack for software basis the functional requirements, market needs, and user persona."
                titleOne="Attention to Details"
                subtitleOne="Being attentive to the smallest details means a reduction in the chances of potential errors. For this reason, our developers and quality analysts check and double-check the code."
                titleTwo="Proper organisation"
                subtitleTwo="To deal with daunting programming, we break up complex tasks into more manageable segments. We can proudly say that we are organised, in any aspect of our work."
                altIconOne="Attebtation to details"
                iconOne="assets/images/svg-icons/attention-to-details.svg"
                altIconTwo="Proper Organization"
                iconTwo="assets/images/svg-icons/proper-organization.svg"
                altOne="Attentions"
                imageOne="assets/images/pages/attentions.jpg"
                altTwo="Organisation"
                imageTwo="assets/images/pages/organisation.jpg"
            />
            <ServicesAllPostTypeSection
                ptop="0"
                title="Being at the highest level of professionalism, we are the masters of a wide range of skills. Our unparalleled performance is an amalgamation of soft skills, experience, aptitude and technical capability. We acknowledge our responsibilities towards our clients."
                subtitle="The specific set of actions blended with our programmer skills leads to the creation of a tangible result. An absolute merging of art and science is exemplified by the incorporation of our ingenuity with technical and analytical elements. Our area of specialisation covers both hard and soft skills, and we suggest only the most appropriate for your business. The proficiency of our specialists in different programming languages enhances the efficiency of our work. In the meantime, we often learn new concepts to apply them to any issue that is prevailing. Our problem-solving capability can boost the performance of your software."
                serviceTitleOne=".Net"
                serviceDescOne="For the automation and optimisation of the business processes, the required solution should have minimum characteristics of usability, availability and accessibility. Our .Net specialists are here to build integrated business solutions, as well as industry-specific and technology-specific solutions, as per your specifications."
                serviceUrlOne="#"
                altOne="Dot Net"
                serviceImageOne="assets/images/technologies/net.png"
                serviceTitleTwo="PHP"
                serviceDescTwo="Open-source technologies embrace the aspects of time-saving, productivity enhancement, and high performance. Especially to build competencies, with a secure, scalable, business-critical and reliable web solutions, PHP could be the answer. Get in touch with our PHP experts to get a meticulous idea on the benefits of PHP."
                serviceUrlTwo="#"
                altTwo="PHP"
                serviceImageTwo="assets/images/technologies/php.png"
                serviceTitleThree="Java"
                serviceDescThree="Are you in search of an agile technology solution to uplift excellence in business? Java is one such programming language with integrated features of cost-effectiveness and independency of the platform. Our Java masters can assist you with your client-server, mobile, cloud, enterprise system and many more."
                serviceUrlThree="#"
                altThree="JAva"
                serviceImageThree="assets/images/technologies/java.png"
                serviceTitleFour="Javascript"
                serviceDescFour="Javascript is a client scripting language that facilitates web development, web applications, server applications, presentations, web server development, game development, graphics, smartwatch applications, mobile applications and many more software products. You can undoubtedly choose us if you are choosing Javascript as a solution for your business."
                serviceUrlFour="#"
                altFour="JavaScript"
                serviceImageFour="assets/images/technologies/javascript.png"
                serviceTitleFive="J2EE"
                serviceDescFive="The J2EE platform consists of a set of services, APIs, and protocols to develop, build and deploy an enterprise application. Whether you want simple or multi-tiered software, if J2EE is your requirement, we can create a web-based enterprise application for you."
                serviceUrlFive="#"
                altFive="J2EE"
                serviceImageFive="assets/images/technologies/j2ee.png"
                serviceTitleSix="Objective-C"
                serviceDescSix="If OS X and iOS are the desiderata for your software, then Objective-C is a great choice. With object-oriented capabilities and dynamic runtime, it can streamline any complexity with ease. The technical adroitness of our team adds to the advantages."
                serviceUrlSix="#"
                altSix="Objective C"
                serviceImageSix="assets/images/technologies/objective-c.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(ProgrammingExpertisePage);