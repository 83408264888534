import React, { memo } from "react";
import ContactFormSection from '../Componenets/ContactFormSection'

const NewContactUsDesignSection = (props) => {
    return (
        <>
            {/* <!-- Contact Form Section Start --> */}
            <div className="contact-form-section section section-padding-t90-b100" style={{ backgroundColor: '#fff' }} data-bg-color="#f8faff">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="section-title-two">
                                <span className="sub-title"></span>
                                <h3 className="title font-big-contact ">{props.contactUsHeadingForm}</h3>
                                <div className="content mb-5 mt-2">
                                    Let us comprehend your project requirements and analyse your business objectives to help us get started on your software project
                                </div>
                                <div className="footer-social-inline">
                                    <a href="https://www.facebook.com/profile.php?id=100057254957799" target="_blank" ><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.linkedin.com/company/xetech" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="https://twitter.com/xetech_global" target="_blank"><i className="fab fa-twitter"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="contact-form">
                                <ContactFormSection />
                                <p className="form-messege"></p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="content content-contact-details mb-5 mt-5">
                                <p className="contactDetails">
                                    <a href={`tel:${props.phoneOne}`}><i className="fas fa-phone-alt icon-contact-small"></i> <span className="text-black text-bold font-14px mb-20"> {props.phoneOne === '' ? '' : `${props.phoneOne}`}</span></a>
                                </p>
                                <p className="contactDetails">
                                    <a href={`tel:${props.phoneTwo}`}><i className="fas fa-phone-alt icon-contact-small"></i> <span className="text-black text-bold font-14px mb-20"> {props.phoneTwo === '' ? '' : `${props.phoneTwo}`}</span></a>
                                </p>
                                <p className="contactDetails">
                                    <a href={`tel:${props.phoneThree}`}><i className="fas fa-phone-alt icon-contact-small"></i> <span className="text-black text-bold font-14px mb-20"> {props.phoneThree === '' ? '' : `${props.phoneThree}`}</span></a>
                                </p>
                                <p className="contactDetails">
                                    <a href={`mailto:${props.emailOne}`}><i className="far fa-envelope-open icon-contact-small"></i><span className="text-black text-bold font-14px mb-20"> {props.emailOne === '' ? '' : `${props.emailOne}`}</span></a>
                                </p>

                                {/* <span className="text-black text-bold font-14px mb-20"> {props.emailTwo === '' ? '' : `${props.emailTwo}`}</span>
                                    <br/>
                                    <span className="text-black text-bold font-14px mb-20"> {props.emailThree === '' ? '' : `${props.emailThree}`}</span> */}

                            </div>
                        </div>
                        <div className="col-md-12 mt-5 border-contact-top pt-10">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <!-- Contact Info Item Start --> */}
                                    <div className="contact-info info-light text-center align-items-center d-flex justify-content-center" data-vivus-hover>
                                        <div className="icon">
                                            <img className="svgInject" src="assets/images/svg/linea/linea-basic-map.svg" alt="Xetech" />
                                        </div>
                                        <div className="info">
                                            <h4 className="text-black">Our Locations</h4>
                                        </div>
                                    </div>
                                    {/* <!-- Contact Info Item End --> */}
                                </div>
                            </div>
                            <div className="row mt-10 contact-page-address">
                                <div className="contact-info col-md-4">
                                    <p className="text-center w-100">
                                        <span className="text-black text-bold font-14px mb-20"> {props.addressOne === '' ? '' : `${props.addressOne}`}</span>
                                    </p>
                                </div>
                                <div className="contact-info col-md-4">
                                    <p className="text-center w-100">
                                        <span className="text-black text-bold font-14px mb-20"> {props.addressTwo === '' ? '' : `${props.addressTwo}`}</span>
                                    </p>
                                </div>
                                <div className="contact-info col-md-4">
                                    <p className="text-center w-100">
                                        <span className="text-black text-bold font-14px mb-20"> {props.addressThree === '' ? '' : `${props.addressThree}`}</span>
                                    </p>
                                </div>
                                {/*<div className="contact-info col-md-3">*/}
                                {/*    <p className="text-center w-100">*/}
                                {/*        <span className="text-black text-bold font-14px mb-20"> {props.addressFour === '' ? '' : `${props.addressFour}`}</span>*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Contact Form Section End --> */}
        </>
    )
}

export default memo(NewContactUsDesignSection);