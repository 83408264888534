import React, { useState, useEffect, memo } from "react";
import { Link } from 'react-router-dom';

const Header = (props) => {
    const [openMenu, setOpenMenu] = useState('close');
    const [openSubMenu, setOpenSubMenu] = useState('close');
    const [openSubMenuTwo, setOpenSubMenuTwo] = useState('close');
    const [stickyClass, setStickyClass] = useState('');

    const listenScrollEvent = (event) => {
        if (window.scrollY > 175) {
            return setStickyClass("is-sticky")
        } else if (window.scrollY < 175) {
            return setStickyClass("")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    return (
        <>
            {/* // <!-- Header Section Start --> */}
            <div className={`header-section header-transparent sticky-header ${stickyClass} `} style={{
                backgroundColor: `${props.page === 'career' || props.page === 'career-details' ? '#030e22' : 'transparent'}`
            }}  >
                <div className="header-inner">
                    <div className="container position-relative">
                        <div className="row justify-content-between align-items-center">

                            {/* <!-- Header Logo Start --> */}
                            <div className="col-xl-2 col-auto order-0">
                                <div className="header-logo">
                                    <Link to='/' onClick={() => {
                                        window.scrollTo(0, 0);
                                    }} >
                                        {stickyClass === '' ?
                                            <img className="dark-logo" src={process.env.PUBLIC_URL + 'assets/images/logo/logo.svg'} alt="Xetech" /> :
                                            <img className="dark-logo" src={process.env.PUBLIC_URL + 'assets/images/logo/footer-logo.svg'} alt="Xetech" />
                                        }

                                    </Link>
                                </div>
                            </div>
                            {/* <!-- Header Logo End --> */}

                            {/* <!-- Header Main Menu Start --> */}
                            <div className="col-auto col-xl d-flex align-items-center justify-content-xl-end justify-content-end order-2 order-xl-1">
                                <div className="menu-column-area d-none d-xl-block position-static">
                                    <nav className="site-main-menu">
                                        <ul>
                                            <li>
                                                <Link to='/'
                                                    onClick={() => {
                                                        window.scrollTo(0, 0);
                                                    }}
                                                ><span className="menu-text">Home</span></Link>
                                            </li>
                                            <li>
                                                <Link to='/about'><span className="menu-text">About Us</span></Link>
                                            </li>
                                            <li className="has-children">
                                                <Link to='/services'><span className="menu-text">Services</span></Link>
                                                <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                                <ul className="sub-menu">
                                                    <li><Link to='/softwareconsulting'><span className="menu-text">Software Consulting</span></Link></li>
                                                    <li><Link to='/integration'><span className="menu-text">Integration</span></Link></li>
                                                    <li><Link to='/technologyspecialists'><span className="menu-text">Technology Specialists</span></Link></li>
                                                    <li><Link to='/testing'><span className="menu-text">Testing</span></Link></li>
                                                </ul>
                                            </li>
                                            <li className="has-children">
                                                <Link to="/technology"><span className="menu-text">Technology</span></Link>
                                                <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                                <ul className="sub-menu">
                                                    <li><Link to='/applications'><span className="menu-text">Applications</span></Link></li>
                                                    <li><Link to='/browserapplications'><span className="menu-text">Browser Applications</span></Link></li>
                                                    <li><Link to='/cloudservices'><span className="menu-text">Cloud Services</span></Link></li>
                                                    <li><Link to='/mobiledevelopment'><span className="menu-text">Mobile Development</span></Link></li>
                                                    <li><Link to='/programmingexpertise'><span className="menu-text">Programming Expertise</span></Link></li>
                                                    <li><Link to='/database'><span className="menu-text">Database</span></Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to='/managesservices'><span className="menu-text">Managed Services</span></Link>
                                            </li>
                                            <li>
                                                <Link to='/work'><span className="menu-text">Products</span></Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*<Link to='/blog'><span className="menu-text">Blog</span></Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <Link to='/career'><span className="menu-text">Careers</span></Link>
                                            </li>
                                            <li>
                                                <Link to='/contact'><span className="menu-text">Contact Us</span></Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* <!-- Header Search Start --> */}
                                <div className="header-search-area ml-xl-7 ml-0 d-none">

                                    {/* <!-- Header Login Start --> */}
                                    {/* <div className="header-search">
                            <a href="#" className="header-search-toggle"><i className="pe-7s-search pe-2x pe-va"></i></a>
                        </div> */}
                                    {/* <!-- Header Login End --> */}
                                </div>
                                {/* <!-- Header Search End --> */}

                                {/* <!-- Header Mobile Menu Toggle Start --> */}
                                <div className="header-mobile-menu-toggle d-xl-none ml-sm-2" onClick={() => setOpenMenu('open')} >
                                    <button className="toggle">
                                        <i className="icon-top"></i>
                                        <i className="icon-middle"></i>
                                        <i className="icon-bottom"></i>
                                    </button>
                                </div>
                                {/* <!-- Header Mobile Menu Toggle End --> */}
                            </div>
                            {/* <!-- Header Main Menu End --> */}

                            {/* <!-- Header Right Start --> */}
                            {/* <div className="col-xl-2 col d-none d-sm-flex justify-content-end order-1 order-xl-2">
                    <a href="#" className="btn btn-light btn-hover-primary">Buy Now</a>
                </div> */}
                            {/* <!-- Header Right End --> */}

                        </div>
                    </div>
                </div>
            </div>





            <div id="site-main-mobile-menu" className={`site-main-mobile-menu ${openMenu === 'open' ? 'MobileMenuOpen' : ''} `}>
                <div className="site-main-mobile-menu-inner">
                    <div className="mobile-menu-header">
                        <div className="mobile-menu-logo">

                            <Link to='/'>
                                <img src="assets/images/logo/logo.png" alt="Xetech" />
                            </Link>
                        </div>
                        <div className="mobile-menu-close" onClick={() => setOpenMenu('close')} >
                            <button className="toggle">
                                <i className="icon-top"></i>
                                <i className="icon-bottom"></i>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-menu-content">
                        <nav className="site-mobile-menu">
                            <ul>
                                <li>
                                    <Link to="/"><span className="menu-text">Home</span></Link>
                                </li>
                                <li>
                                    <Link to='/about'><span className="menu-text">About Us</span></Link>
                                </li>
                                <li className="has-children" onClick={() => {
                                    openSubMenu === 'open' ? setOpenSubMenu('close') : setOpenSubMenu('open')
                                    // setOpenSubMenu('open')
                                }} >
                                    <Link to='/services'><span className="menu-text">Services</span></Link>
                                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                    <ul className={`sub-menu ${openSubMenu === 'open' ? 'expand' : ''} `} >
                                        <li><Link to='/softwareconsulting'><span className="menu-text">Software Consulting</span></Link></li>
                                        <li><Link to='/integration'><span className="menu-text">Integration</span></Link></li>
                                        <li><Link to='/technologyspecialists'><span className="menu-text">Technology Specialists</span></Link></li>
                                        <li><Link to='/testing'><span className="menu-text">Testing</span></Link></li>
                                    </ul>
                                </li>
                                <li className="has-children"
                                    onClick={() => {
                                        openSubMenuTwo === 'open' ? setOpenSubMenuTwo('close') : setOpenSubMenuTwo('open')
                                        // setOpenSubMenu('open')
                                    }}
                                >
                                    <Link to='/technology'><span className="menu-text">Technology</span></Link>
                                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                                    <ul className={`sub-menu ${openSubMenuTwo === 'open' ? 'expand' : ''} `}>
                                        <li><Link to='/applications'><span className="menu-text">Applications</span></Link></li>
                                        <li><Link to='/browserapplications'><span className="menu-text">Browser Applications</span></Link></li>
                                        <li><Link to='/cloudservices'><span className="menu-text">Cloud Services</span></Link></li>
                                        <li><Link to='/mobiledevelopment'><span className="menu-text">Mobile Development</span></Link></li>
                                        <li><Link to='/programmingexpertise'><span className="menu-text">Programming Expertise</span></Link></li>
                                        <li><Link to='/database'><span className="menu-text">Database</span></Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to='/work'><span className="menu-text">Work</span></Link>
                                </li>
                                {/*<li>*/}
                                {/*<Link to='/blog'><span className="menu-text">Blog</span></Link>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to='/managesservices'><span className="menu-text">Managed Services</span></Link>
                                </li>
                                <li>
                                    <Link to='/work'><span className="menu-text">Products</span></Link>
                                </li>
                                <li>
                                    <Link to='/career'><span className="menu-text">Careers</span></Link>
                                </li>
                                <li>
                                    <Link to='/contact'><span className="menu-text">Contact Us</span></Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>




        </>
    )
}

export default memo(Header);