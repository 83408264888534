import React, { memo, useEffect } from "react";
import FooterSection from "../Componenets/FooterSection";
import Header from '../Componenets/Header';
import PageTitleSection from "../Componenets/PageTitleSection";
import AboutUsSection from "../Componenets/AboutUsSection";
import CTASection from "../Componenets/CTASection";
import ContactInformationSection from '../Componenets/ContactInformationSection';
import AboutUsWhoWeAreSection from "../Componenets/AboutUsWhoWeAreSection";
import WhyChooseUsSection from "../Componenets/WhyChooseUsSection";
import ServicesAboutThreeColSection from "../Componenets/ServicesAboutThreeColSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const AboutUsPage = () => {

    const titleWebsite = "About Us | Xetech";
    const descriptionWebsite = "We are an emerging healthcare solution provider specialising in various technological innovations and breakthroughs. Our teams of developers, engineers and architects work in an open, flexible and agile environment to deliver transformative technologies and solutions. ";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitleSection
                title="Trusted By The World's Best Organizations"
                pagetitle="About Us"
                subtitle="We are trusted by our clients and have a reputation for the best services in the field"
                bgImage="assets/images/pages/about-new-page.jpg"
            />
            <ContactInformationSection
                title="Our Fortes"
                subtitle=""
                iconOne="assets/images/svg-icons/trusted.svg"
                titleOne="We are trusted"
                titleOneDesc="Champion digital strategies"
                altOne="Champion digital strategies"
                iconTwo="assets/images/svg-icons/professional.svg"
                titleTwo="We are professional"
                titleTwoDec="Personalised cloud mobility"
                altTwo="Personalised cloud mobility"
                iconThree="assets/images/svg-icons/flexible.svg"
                titlethree="We are flexible"
                titleThreeDec="End-to-end business automation"
                altThree="End-to-end business automation"
            />
            <AboutUsWhoWeAreSection
                title="Who We Are?"
                subtitle="In a world of digital service providers, we consider our award-winning IT software solution engineering team as a blend of forward and dynamic thinkers as well as advanced and talented individuals proffering world-class software solutions across the globe."
                details="Formerly known as Hicom Information Services, part of the Hicom group, we started our operations in India more than a decade ago and have a combined experience of almost 30+ years. Subsequently, the two businesses separated, and the business identity changed to XeTech. With long years of extensive experience and expertise in the software industry, XeTech has produced many enterprise-grade solutions with digital mobility and successfully delivered them to our clients with guaranteed satisfaction. Being a Microsoft Gold Partner and Azure Cloud Solution Provider, we at XeTech look at business processes through the eyes of digital empowerment. From cloud applications to business mobility, AI infrastructure to software and app development, our talented and committed team has the capability to provide end-to-end services for every detailed and refined requirement. Our real strength lies in the team members' knowledge, skills, and talent. We have built industry relationships that are defined through mutual success. Our clients are our most excellent resource; hence our services are personalized with an assurance and a remit of exceptional quality.           Being a Microsoft Gold partner, we at XeTech look at business processes through the eyes of digital empowerment. From cloud applications to business mobility, AI infrastructure to software and app development, not only our talented and committed team has the capability to provide end-to-end services for every detailed and refined requirement, but also our real strength lies in the knowledge, skills and talent of the team members. We have built industry relationships that are defined through mutual success. Our clients are our most excellent resource; hence our services are personalised with an assurance and a remit of exceptional quality."
                buttontext="Read More"
                buttonUrl=""
            />
            <WhyChooseUsSection
                title="Why Choose Us"
                subtitle=""
                titleOne="Upscale your business persona"
                descOne="Every service that we provide is not only aimed to simply fulfil your business goals but also to enhance the quality of your business’ digital persona."
                titleTwo="Flexible service provision"
                descTwo="From SMEs to enterprise-grade services, we tailor our service structure to meet your business requirements and expectations."
                titleThree="Intelligent process"
                descThree="With our streamlined and ROI-centric approach and your requirements put together, you can never go wrong on your digital journey"
                titleFour="All-round support"
                descFour="Our team is available and approachable for all your queries and needs at every step of the way and at any time. Simply reach out to us."
            />
            <AboutUsSection
                title="What We Do"
                subtitle="Learn how we are helping customers deliver ‘High Performance’"
                // headingtitle="Every day brings new challenges"
                headingsubtitle="Software Testing, Web & App Development"
                buttontext="Know More"
                imageOne="assets/images/pages/about-services.jpg"
                altOne="services"
                imageTwo="assets/images/pages/about-services-software.jpg"
                altTwo="software"
                imageThree="assets/images/shape-animation/about-shape-1.png"
                altThree="shape"
                url="services"
            >
                Our multi-talented team comprises software professionals and experts in versatile dimensions ranging from Software Consultancy to Software Testing, Web Development to Mobile App Development, Cloud services, Microsoft and Open Source Technology-based Software Solutions and much more.
            </AboutUsSection>
            <ServicesAboutThreeColSection
                iconOne="assets/images/svg-icons/company.svg"
                altOne="Company"
                titleOne="Our Mission"
                titleOneDesc="The XeTech mission has always been to bring together the best of the resources and tech-minds to offer our clients an experience they will never forget. We strive every day to strengthen our team, enhance our knowledge and understanding of the latest updates in the digital realm."
                urlOne="managesservices"
                iconTwo="assets/images/svg-icons/vision.svg"
                altTwo="Vision"
                titleTwo="Our vision"
                titleTwoDesc="Our vision is to become the market leader in providing innovative and affordable software solutions. We visualise in a world that allows for the accessibility of modern technological resources and digital transformations to all businesses, big and small."
                urlTwo="work"
                iconThree="assets/images/svg-icons/react-core.svg"
                altThree="Core values"
                titleThree="Our core values"
                titleThreeDesc="XeTech is a brand built on transparency and quality-assured ethical business processes. If you partner with us, you will acquire in-depth knowledge of every aspect of our processes with a clear understanding of the ROI. We believe in partnerships based on trust and long-lasting successful endeavours."
                urlThree="technology"
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            {/* <TestimonialSection title="What our customers are saying about our services" /> */}
            <CTASection
                title="Delivering Technology Solutions. Want to know more, Let's connect"
                subtitle=""
                buttonText="Contact Us"
                buttonUrl=""
                iconOne="assets/images/svg-icons/react.svg"
                altOne="React"
                iconTwo="assets/images/svg-icons/angular.svg"
                altTwo="Angular"
                iconThree="assets/images/svg-icons/java.svg"
                altThree="Java"
                iconFour="assets/images/svg-icons/oracle.svg"
                altFour="Oracle"
                iconFive="assets/images/svg-icons/dotnet.svg"
                altFive="Dotnet"
                iconSix="assets/images/svg-icons/php.svg"
                altSix="PHP"
                iconSeven="assets/images/svg-icons/css3.svg"
                altSeven="CSS 3"
                iconEight="assets/images/svg-icons/html5.svg"
                altEight="HTML 5"
                iconNine="assets/images/svg-icons/sql.svg"
                altNine="SQL"
                urlButton="/contact"
            />
            <FooterSection />
        </>
    )
}

export default memo(AboutUsPage);