import React, { memo } from "react";
import ButtonDesignSection from "./ButtonDesignSection";

const CTASection = (props) => {
    return (
        <>
            {/* <!-- CTA Section Start -->*/}
            <div className="cta-section section section-padding-250 res-p-top">
                <div className="container text-center icon-up-down-animation">
                    {/* <!-- Section Title Start -->*/}
                    <div className="section-title text-center"
                    // data-aos="fade-up"
                    >
                        <h2 className="title fz-34">{props.title}</h2>
                        <p className="sub-title title-w-90">{props.subtitle}</p>
                    </div>
                    {/* <!-- Section Title End -->*/}
                    {/* <a className="btn btn-primary btn-hover-secondary" href="#">Contact Us</a> */}
                    <ButtonDesignSection title="Contact Us" url={props.urlButton} className="" />

                    {/* <!-- Icon Animation Start -->*/}
                    <div className="shape shape-1">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconOne}`} alt={props.altOne} />
                        </span>
                    </div>
                    <div className="shape shape-2">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt={props.altTwo} />
                        </span>
                    </div>
                    <div className="shape shape-3">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconThree}`} alt={props.altThree} />
                        </span>
                    </div>
                    <div className="shape shape-4">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconFour}`} alt={props.altFour} />
                        </span>
                    </div>
                    <div className="shape shape-5">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconFive}`} alt={props.altFive} />
                        </span>
                    </div>
                    <div className="shape shape-6">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconSix}`} alt={props.altSix} />
                        </span>
                    </div>
                    <div className="shape shape-7">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconSeven}`} alt={props.altSeven} />
                        </span>
                    </div>
                    <div className="shape shape-8">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconEight}`} alt={props.altEight} />
                        </span>
                    </div>
                    <div className="shape shape-9">
                        <span>
                            <img src={`${process.env.PUBLIC_URL + props.iconNine}`} alt={props.altNine} />
                        </span>
                    </div>
                    {/* <!-- Icon Animation End -->*/}
                </div>
            </div>
            {/* <!-- CTA Section End -->*/}
        </>
    )
}

export default memo(CTASection);