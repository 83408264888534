import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllFivePostTypeSection from "../Componenets/ServicesAllFivePostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const DatabasePage = () => {

    const titleWebsite = "Database | Xetech";
    const descriptionWebsite = "Being a specialised software development company, we help our clients with database solutions and extract valuable business insights from their existing database and data to understand their needs better using analysis tools and artificial intelligence.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Database Design, Development & Analysis  With Precision"
                pagetitle="Database"
                subtitle="Complete data management, development, migration, analysis and reporting solutions using the best in world tools and backed by artificial intelligence"
                bgImage="assets/images/technologies/database.jpg"
            />
            <AboutUsPageSection
                title="Our database programmers, architect and DBA"
                subTitle="We keep everything simple to help you in apprehending your database with ease."
                titleOne="Technology"
                subtitleOne="For on-premise to cloud, cloud to on-premise and cloud to cloud scenarios, we offer Cloud Integration Services for the application, as per your long-term business goals."
                titleTwo="Security"
                subtitleTwo="Our primary technology selection, Microsoft SQL Server, is one of the most secured databases. We further ensure We specialise in the following database technologies."
                iconOne="assets/images/svg-icons/technology.svg"
                iconTwo="assets/images/svg-icons/security.svg"
                imageOne="assets/images/pages/technology.jpg"
                imageTwo="assets/images/pages/security.jpg"
            />
            <ServicesAllFivePostTypeSection
                title="An accurate and well-ordered database is crucial to success for any business or organisation; therefore, our team of experts will guarantee an optimum database solution."
                subtitle="Being a specialised software development company, we not only help our clients with database solutions but also extract valuable business insights from their existing database and data to understand their needs better.
We understand ways around a database, and the business use of data, the importance of governance and standards, balanced against constant demand for delivery. We will make sure we push boundaries of innovation. Whether it is a remote or cloud database, local database, synchronised or replicated, or a multi-tenanted database, database migration or perhaps move into cloud or a hybrid data warehouse, we are masters in all."
                serviceTitleOne="SQL"
                serviceDescOne="Microsoft SQL server is where we feel most feel at home. Being Microsoft certified gold partner and having Microsoft certified SQL Masters in our team, We can ensure you a stressfree journey with any MS SQL requirement including SSAS, SSRS and PowerBI"
                serviceUrlOne="#"
                altOne="SQL"
                serviceImageOne="assets/images/technologies/sql.png"
                serviceTitleTwo="MySQL"
                serviceDescTwo="Being one of the most popular Open Source Relational SQL Database Management System, most of our open source development is on MySQL. With years of experience developing MySQL solution, our team will help you deliver solutions of any complexity."
                serviceUrlTwo="#"
                altTwo="Mysql"
                serviceImageTwo="assets/images/technologies/mysql.png"
                serviceTitleThree="Oracle"
                serviceDescThree="The Oracle database management system is preferred for running OLTP and DW database workloads. If you are in a search for genuine Oracle database experts, then you are at the correct place. Bring your ideas to us, and we will create something extraordinary, jointly."
                serviceUrlThree="#"
                altThree="Oracle"
                serviceImageThree="assets/images/technologies/oracle.png"
                serviceTitleFour="MariaDB"
                serviceDescFour="MariaDB Server favour performance, stability, and openness, over other features, if this is what you need for your business, we would offer the best that we have now while keeping in mind about the future technologies that we can have."
                serviceUrlFour="#"
                altFour="Maria DB"
                serviceImageFour="assets/images/technologies/mariadb.png"
                serviceTitleFive="SQL Lite"
                serviceDescFive="Implement the database concept in Android Development with SQLite. If you don’t want a separate server process for your database and wish for a self-contained, light-weight, zero-configuration, disk-based transactional database engine, then our SQLite specialists are longing to work with you."
                serviceUrlFive="#"
                altFive="Sql Lite"
                serviceImageFive="assets/images/technologies/sql-lite.png"
                serviceTitleSix="Pharmacy Integration"
                serviceDescSix="The lab results accumulated in one place can be shared anywhere with ease. Automate your lab data and systems and make it valuable with the lab integration service offered by our esteemed professionals."
                serviceUrlSix="#"
                altSix="Microsoft Azure"
                serviceImageSix="assets/images/technologies/microsoft-azure.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(DatabasePage);