import { React, useEffect } from 'react';
import FooterSection from "../Componenets/FooterSection";
import Header from '../Componenets/Header';
import PageCareerNewDesign from '../Componenets/PageCareerNewDesign';
import PageTitleCareerSection from '../Componenets/PageTitleCareerSection';

const CareerPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    return (
        <>
            <Header page="career" />
            <PageCareerNewDesign
                pagetitle="Integration"
                bgImage="assets/images/pages/career-bg.jpg"
                title="Careers for Experienced Professionals."
                desc="Xetech is innovation-led information technology (IT) consulting firms specialising in, IT managed services, IT strategy consulting services , cloud management, and bespoke software development in a variety of different languages and on a number of different platforms.  We are a part of an established, marketing leading UK software house, with software solutions in a number of different fields, including healthcare, retail, banking, manufacturing, and compliances."
            />
            <PageTitleCareerSection />
            <FooterSection />
        </>
    )
}
export default CareerPage;
