import React, {memo} from "react";
import { Link } from "react-router-dom";

const ButtonDesignSection = (props) =>{
    const bordered = props.style;
    return(
        <>
            <Link to={props.url} className={` ${props.class} btn ${ bordered === 'border' ? 'btn-outline-white btn-hover-primary' : 'btn-primary btn-hover-secondary' } `}>{props.title}</Link>
        </>
    )
}

export default memo(ButtonDesignSection);