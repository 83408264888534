import React, { memo } from "react";
import ButtonDesignSection from "./ButtonDesignSection";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AboutTextSection = (props) => {
    return (
        <>
            <div className="section section-padding-t90 section-padding-bottom-200">
                <div className="container">
                    <div className="section-title text-center"
                    // data-aos="fade-up"
                    >
                        <h2 className="title">{props.title}</h2>
                        <p className="sub-title title-w-90 ">{props.subtitle}</p>
                    </div>
                    <div className="row">
                        <div className="col-xl-7 col-lg-6 col-12"
                        // data-aos="fade-up"
                        >
                            <div className="about-image-area">
                                <div className="about-image js-tilt">
                                    <LazyLoadImage src={`${process.env.PUBLIC_URL + props.imageOne}`} alt={props.altOne} effect="blur" />
                                </div>
                                <div className="about-image js-tilt">
                                    <LazyLoadImage src={`${process.env.PUBLIC_URL + props.imageTwo}`} alt={props.altTwo} effect="blur" />
                                </div>
                                <div className="shape shape-1 scene">
                                    <span data-depth="1">
                                        <LazyLoadImage src={`${process.env.PUBLIC_URL + props.imageThree}`} alt="shape" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-12"
                        // data-aos="fade-up"
                        >
                            <div className="about-content-area">
                                <div className="section-title-two">
                                    <span className="sub-title">{props.headingtitle}</span>
                                    <h3 className="title">{props.headingsubtitle}</h3>
                                </div>

                                <p className="pb-3">{props.children}</p>
                                <ButtonDesignSection title={props.buttontext} url={props.url} className="btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4" />
                            </div>
                        </div>

                    </div></div>
            </div>
        </>
    )
}

export default memo(AboutTextSection);