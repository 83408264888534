import React, { memo } from "react";

const ServicesAboutThreeColSection = (props) =>{
    return(
        <>
            {/* {/* <!-- Service Section Start --> */}
        <div className="section section-padding-t90-b100" data-bg-color="#f8faff" style={{backgroundColor:`#f8faff`}}>
            <div className="container">
                <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 story-icon-redefined">

                    {/* <!-- Icon Box Start --> */}
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >

                        <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt={props.altOne} />
                                </div>
                            <div className="content">
                                <h3 className="title">{props.titleOne}</h3>
                                <div className="desc">
                                    <p>{props.titleOneDesc}</p>
                                </div>
                                {/*<a href={props.urlOne} className="link">Learn More</a>*/}
                            </div>
                        </div>

                    </div>
                    {/* <!-- Icon Box End --> */}

                    {/* <!-- Icon Box Start --> */}
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >

                        <div className="icon-box text-center" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt={props.altTwo} />

                                </div>
                                <div className="content">
                                <h3 className="title">{props.titleTwo}</h3>
                                <div className="desc">
                                    <p>{props.titleTwoDesc}</p>
                                </div>
                                {/*<a href={props.urlTwo} className="link">Learn More</a>*/}
                            </div>
                        </div>

                    </div>
                    {/* <!-- Icon Box End --> */}
                </div>

                <div className="row row-cols-lg-1 row-cols-md-2 row-cols-sm-2 row-cols-1 mt-10 mb-n6 story-icon-redefined">
                    
                    {/* <!-- Icon Box Start --> */}
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >

                        <div className="icon-box text-center" data-vivus-hover>
                            <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt={props.altThree} />

                                </div>
                                <div className="content">
                                <h3 className="title">{props.titleThree}</h3>
                                <div className="desc">
                                    <p>{props.titleThreeDesc}</p>
                                </div>
                                <a href={props.urlThree} className="link">Learn More</a>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Icon Box End --> */}
                </div>

            </div>
        </div>
        {/* <!-- Service Section End --> */}
        </>
    )
}


export default memo(ServicesAboutThreeColSection);