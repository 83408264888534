import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import ServicesAllPostTypeSection from "../Componenets/ServicesAllPostTypeSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const SoftwareConsultingPage = () => {

    const titleWebsite = "Software Consulting | Xetech";
    const descriptionWebsite = "We are here to provide as much or as little end-to-end support needed.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="A Software Partner You Can Count On."
                pagetitle="Software Consulting"
                subtitle="Leverage custom design thinking approach for software development life-cycle, blended with the latest technology stack and cost benefit-proven methodologies"
                bgImage="assets/images/services/software-consulting.jpg"
            />
            <ServicesAllPostTypeSection
                title="Software Consulting Services"
                subtitle="Our Software Development Consultants comprehend your requirements not only from a business perspective but also from a realistic viewpoint. Requirement analysis, collaborative prototyping, application structure, technical specification, quality assurance, support plan, development methodology, coding standards and system management are a part of business, system and operational analyses that we facilitate."
                serviceTitleOne="Digital Transformation"
                serviceDescOne="With an emphasis on performance enhancement, we redefine the concept of Digital Transformation for you. We technologise ourselves regularly to provide your business with a cost-effective way to ameliorate productivity and profitability."
                serviceUrlOne="#"
                altOne="Digital Transformation"
                serviceImageOne="assets/images/services/digital-transformation.png"
                serviceTitleTwo="Product Conceptualization"
                serviceDescTwo="To create a great product, we need to conceptualise it first. As a state-of-the-art software company, we use pioneering methods for the conceptualisation of your ideas in the best way possible."
                serviceUrlTwo="#"
                altTwo="Product Conceptualization"
                serviceImageTwo="assets/images/services/product-conceptualization.png"
                serviceTitleThree="Cloud Strategy"
                serviceDescThree="Speed, agility, scalability, responsiveness and cost-effectiveness are some of the benefits of Cloud Strategy. Our second to none cloud strategy experts are here to assist you with our pre-eminent services."
                serviceUrlThree="#"
                altThree="Cloud strategy"
                serviceImageThree="assets/images/services/cloud-strategy.png"
                serviceTitleFour="Integration & Migration"
                serviceDescFour="We offer services in Integration and Migration to assist you for its successful implementation. Maintaining data integrity and protecting the data during migration is a must for an inexorable business."
                serviceUrlFour="#"
                altFour="Integration & Migration"
                serviceImageFour="assets/images/services/integration-and-migration.png"
                serviceTitleFive="Mobile Development"
                serviceDescFive="Whether it is Android, IoS, Hybrid or Cross-platform development, we have experts for all. We are here to assist you in creating an innovative, user-friendly and interactive mobile app for your business."
                serviceUrlFive="#"
                altFive="Mobile Development"
                serviceImageFive="assets/images/services/mobile-development.png"
                serviceTitleSix="Web App Development"
                serviceDescSix="Being experts in agile technologies, we are here to create a flexible, cost-effective and dynamic web application with optimum resources for your business. Carve your vision with us to get what you wish."
                serviceUrlSix="#"
                altSix="Web App Development"
                serviceImageSix="assets/images/services/web-app-development.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(SoftwareConsultingPage);