import { React } from 'react';

const PageCareerNewDesign = (props) => {
    return (
        <>
            <section className="section pt-35 pb-15" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundColor: '#030e22', minHeight: '45vh', backgroundPosition: 'right' }} data-overlay="0.7" aria-label={props.pagetitle} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="careerPageHeading text-white">
                                {/*Careers for Experienced <span className="spanHeadingDesignLight text-white">Professionals.</span>*/}
                                {props.title}
                            </h1>
                            <p className="text-white mt-2 pt-5">
                                {props.desc}
                            </p>
                        </div>
                        <div className="col-md-4 pt-10">
                            <h2 className="career-text-bolder text-left text-white">250+</h2>
                            <p className="text-white">
                                Projects Completed
                            </p>
                        </div>
                        <div className="col-md-4 pt-10">
                            <h2 className="career-text-bolder text-left text-white">100+</h2>
                            <p className="text-white">
                                Experienced Professional
                            </p>
                        </div>
                        <div className="col-md-4 pt-10">
                            <h2 className="career-text-bolder text-left text-white">12+</h2>
                            <p className="text-white">
                                Years Experience
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageCareerNewDesign;