import React, { memo, Component } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

class NewsLetterSection extends Component {

    componentDidMount() {
        loadCaptchaEnginge(4);
    };

    constructor(props) {
        super(props)

        this.state = {
            ToEmail: '',
            ButtonText: 'Subscribe',
            ErrorMsg: '',
            ErrorColor: '',
            displayCaptcha: 'none',
            Popup: 'none',
            EPopUp: 'none'
        }
    }


    submitSubcribeEmail = (event) => {
        event.preventDefault()
        let user_captcha = document.getElementById('user_captcha_input').value;


        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (this.state.ToEmail == '') {
            this.setState({
                ErrorMsg: 'Please fill Email Id',
                ErrorColor: 'red'
            })
        }
        else if (regex.test(this.state.ToEmail) == false) {
            this.setState({
                ErrorMsg: 'Email Id format is wrong',
                ErrorColor: 'red'
            })
        }
        else if (!this.state.ToEmail == '' && regex.test(this.state.ToEmail) == true) {
            this.setState({
                displayCaptcha: 'flex'
            })

            if (user_captcha == '') {
                this.setState({
                    ErrorMsg: 'Captcha is empty',
                    ErrorColor: 'red'
                })
            }
            else if (validateCaptcha(user_captcha) == false) {
                this.setState({
                    ErrorMsg: 'Captcha is wrong',
                    ErrorColor: 'red'
                })
            }
            else if (validateCaptcha(user_captcha) == true) {
                this.setState({
                    ErrorMsg: 'Captcha is Correct',
                    ErrorColor: 'green',
                    EPopUp: 'flex'
                })
                const data = { "ToEmail": this.state.ToEmail }
                //    /* api call start */
                fetch("email?emailAddress=" + data.ToEmail)
                    .then(response => {
                        let statusCode = response.status,
                            success = response.ok;

                        if (success) {
                            this.setState({
                                Popup: "show",
                                ErrorMsg: "",
                                ButtonText: "Subscribe",
                                ToEmail: " ",
                                displayCaptcha: "none",
                                EPopUp: 'none'
                            })
                        }
                    })
            }
        }
    }


    render() {
        return (
            <>
                <div className="popupShowEarlier" style={{ display: this.state.EPopUp === 'none' ? 'none' : 'flex' }} >
                    <div className="loaderForm">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                {/* {/* <!-- Newsletter Section Start --> */}
                <div className="section section-padding-t110-b120 newsletter-section" data-bg-color="#000" data-overlay="0.7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/images/bg/newsletter.jpg'})` }} data-bg-image={require('../assets/images/bg/newsletter.jpg')}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                {/* <!-- Newsletter Content Start --> */}
                                <div className="newsletter-content">
                                    {/* <!-- Section Title Start --> */}
                                    <div className="section-title color-light text-center"
                                    // data-aos="fade-up"
                                    >
                                        <h2 className="title">{this.props.title}</h2>
                                        <p className="sub-title fz-18">{this.props.subtitle}</p>
                                    </div>
                                    {/* <!-- Section Title End --> */}
                                    {/* <!-- Newsletter Form Start --> */}
                                    <div className="newsletter-form">
                                        <p className="text-center" style={{ 'color': this.state.ErrorColor }} >
                                            {this.state.ErrorMsg}
                                        </p>
                                        <form action="#" className="d-flex justify-content-center flex-column">

                                            <div className="col-md-12 d-flex flex-row">
                                                <input type="email" placeholder="Enter your email" value={this.state.ToEmail} onChange={(event) => {
                                                    this.setState({ ToEmail: event.target.value, ButtonText: 'Send' })
                                                }}
                                                    onMouseLeave={() => {
                                                        if (this.state.ToEmail == "") {
                                                            this.setState({
                                                                ButtonText: 'Subscribe'
                                                            })
                                                        } else {
                                                            this.setState({
                                                                ButtonText: 'Send'
                                                            })
                                                        }
                                                    }}
                                                name="mail" required className="res-filed" />
                                                <button className="btn btn-primary res-filed btn-hover-secondary" onClick={this.submitSubcribeEmail} >{this.state.ButtonText}</button>
                                            </div>

                                            <div className="col-md-12 flex-column mt-4 bg-white" style={{
                                                display: `${this.state.displayCaptcha}`
                                            }} id="user_captcha_Box">
                                                <div className="row captcha-design-box">
                                                    <div className="col-md-6">
                                                        <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" className="form-control h-100" type="text"></input>
                                                    </div>
                                                    <div className="col-md-6 captcha-text-design-box ">
                                                        <LoadCanvasTemplate />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <!-- Newsletter Form End --> */}
                                </div>
                                {/* <!-- Newsletter Content End --> */}
                            </div>
                        </div>
                    </div>

                    {/* <!-- Animation Shape Start --> */}
                    <div className="shape shape-1 scene">
                        <span data-depth="1">
                            <img src="assets/images/shape-animation/newsletter-shape.png" alt="Xetech" />
                        </span>
                    </div>
                    {/* <!-- Animation Shape End --> */}
                </div>
                {/* <!-- Newsletter Section End --> */}

                <div className={`modalPopupDesign modal fade ${this.state.Popup == 'none' ? '' : 'show'}`} style={{ display: this.state.Popup == 'none' ? 'none' : 'block' }}  >
                    <div className="modal-dialog modal-dialog-centered" tabindex="-1">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Thanks for Subscribe</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                                        this.setState({ Popup: 'none' })
                                        this.setState({ ButtonText: 'Subscribe' })
                                        this.setState({ ToEmail: '' })
                                        this.setState({ EPopUp: 'none' })
                                        document.getElementById("user_captcha_input").value = null;
                                    }} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>We will send you updates in Email.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal" onClick={() => {
                                        this.setState({ Popup: 'none' })
                                        this.setState({ ButtonText: 'Subscribe' })
                                        this.setState({ ToEmail: '' })
                                        this.setState({ EPopUp: 'none' })
                                        document.getElementById("user_captcha_input").value = null;
                                    }} >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default memo(NewsLetterSection);