import React, { memo, useEffect } from "react";
import FooterSection from "../Componenets/FooterSection";
import Header from '../Componenets/Header';
import PageTitleSection from "../Componenets/PageTitleSection";
import IconManagedServicesCareHubSection from '../Componenets/IconManagedServicesCareHubSection';
import ManagesServicesAboutTextSection from '../Componenets/ManagesServicesAboutTextSection';
import ServicesAllFivePostTypeSection from '../Componenets/ServicesAllFivePostTypeSection';
const ManagesServicesPage = () => {

    const titleWebsite = "Managed Service | Xetech";
    const descriptionWebsite = "At Xetech, we have assisted businesses with everything from planning and designing their IT infrastructure to remotely everyday maintenance. We offer a variety of services which allow you to make sure that our plans meet all your IT requirements.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Expert In Cloud Services & Digital Transformation!"
                pagetitle="Managed Services"
                subtitle="We will simplify the pathway to digital transformation with a tailored cloud strategy"
                bgImage="assets/images/pages/managed-services.jpg"
            />
            <ManagesServicesAboutTextSection />
            <IconManagedServicesCareHubSection
                title="Infrastructure Management Services We Offer"
                subtitle="At Xetech, We have been assisting business with everything from planning and design of their IT infrastructure to everyday maintenance on a remote basis. We offer a variety of services which allow you to make sure that our plans meet all of your IT requirements. Some of the key services we offer include."
                subheading="Advantages of Outsourcing Infrastructure Management to Xetech"
                iconOne="assets/images/workicons/cost1.png"
                titleOne="Cost-benefit cloud analysis"
                iconTwo="assets/images/workicons/migration.png"
                titleTwo="Migration of existing applications and data to Azure and AWS"
                iconThree="assets/images/workicons/design.png"
                titleThree="Design, implementation and management of public, private and hybrid clouds"
                iconFour="assets/images/workicons/cloud-backup.png"
                titleFour="Cloud backup and disaster recovery"
                iconFive="assets/images/workicons/maintenance.png"
                titleFive="Monitoring & Preventative Maintenance"
                iconSix="assets/images/workicons/application.png"
                titleSix="Cloud Automation: - Automating all day-to-day activities to ensure minimal human intervention and security breaches"
                iconSeven="assets/images/workicons/cloud-desktop.png"
                titleSeven="Cloud Virtualisation, Cloud Desktop, VDI"
                iconEight="assets/images/workicons/application.png"
                titleEight="Cloud Best Practices Implementation"
                iconNine="assets/images/workicons/infrastructure.png"
                titleNine="Infrastructure as a Service"
                iconTen="assets/images/workicons/cost.png"
                titleTen="Fixed Cost Management - Network, Desktop and Security Services"
                iconEleven="assets/images/workicons/support.png"
                titleEleven="Unlimited HelpDesk & Onsite Support"
                iconTweleve="assets/images/workicons/hybrid.png"
                titleTweleve="Private and hybrid cloud integration"
            />
            <ServicesAllFivePostTypeSection
                title="Advantages of Outsourcing Infrastructure Management to Xetech"
                subtitle="We are very flexible. We understand that not every enterprise is the same when it comes to cloud needs, knowledge, and ability. Therefore, our managed cloud service can customize our management capabilities to suit your specific cloud needs better. By providing multiple different managed services, we can help you decide which service would be the best suite for your enterprise needs. A few of Immediate benefits can be gained by outsourcing IT services through Xetechs Managed Services"
                serviceTitleOne="Cost Savings"
                serviceDescOne="As a trusted partner, We source the best cloud solution to avoid expensive consulting and realize a lower total cost of ownership.
Staffing a full-time in-house team is extremely expensive, and wholly unnecessary when Xetech's flexibility managed cloud services can deliver 24/7, dedicated services at a fraction of the cost. Reduction in the workforce, increased efficiency in performance and capacity planning of Xetech's experts can upshoot in up to 50% reduction in your total IT operational costs"                serviceUrlOne="#"
                serviceImageOne="assets/images/managed-services/cost-saving.png"
                serviceTitleTwo="Flexible and Scalable Solutions"
                serviceDescTwo="Our cloud solutions are inherently flexible. We will easily upscale or downscale your rapid changes in IT requirements as and when required. We handle your increased business needs and changes by proactively exploring and identifying enhancements that allow for quick and easy allocation of resources in a monitored environment where overloading is never an apprehension."
                serviceUrlTwo="#"
                serviceImageTwo="assets/images/technologies/angular.png"
                serviceTitleThree="High Availability, Reliability and Performance"
                serviceDescThree="Reliability, high availability and performance have always been a significant concern on distributed systems. We configure highly available and reliable services in the cloud, maintaining customer confidence, satisfaction and preventing revenue losses. We will ensure that your cloud infrastructure is continuously functioning at optimal levels and take measures to identify potential threats with persistence to provide uninterrupted service around the clock."
                serviceUrlThree="#"
                serviceImageThree="assets/images/technologies/react.png"
                serviceTitleFour="Disaster Recovery"
                serviceDescFour="Our cloud architects will ensure that cloud technology enables efficient disaster recovery, regardless of the type or intensity of workloads. We configure application-aware replication and application-consistent backup that scales based on your business needs in its entirety, including applications, operating systems, databases and documents (file shares)."
                serviceUrlFour="#"
                serviceImageFour="assets/images/technologies/vue.png"
                serviceTitleFive="Expert, Dedicated, Around-the-Clock Support"
                serviceDescFive="Xetech's ISO-accredited services provide total peace of mind. You'll never deal with an impersonal call centre to get support. Whether you need assistance overnight, during the week, or even on the weekend, one of our specialists will be available to resolve your technical issues 24x7x365."
                serviceUrlFive="#"
                serviceImageFive="assets/images/technologies/typescript.png"
                serviceTitleSix="Pharmacy Integration"
                serviceDescSix="The lab results accumulated in one place can be shared anywhere with ease. Automate your lab data and systems and make it valuable with the lab integration service offered by our esteemed professionals."
                serviceUrlSix="#"
                serviceImageSix="assets/images/technologies/windows-form.png"
            />
            <FooterSection />
        </>
    )
}

export default memo(ManagesServicesPage);