import React, { memo } from "react";

const WhyChooseUsSection = (props) =>{
    return(
        <>
        <>
        {/* <!-- Contact Information Section Start --> */}
        <div className="section section-padding-t90-b100">
            <div className="container shape-animate">
                {/* <!-- Section Title Start --> */}
                <div className="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8"
                // data-aos="fade-up"
                >
                    <h2 className="title">{props.title}</h2>
                    <p className="sub-title title-w-90">{props.subtitle}</p>
                </div>
                {/* <!-- Section Title End --> */}

                <div className="row row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >
                        {/* <!-- Contact Information Start --> */}
                        <div className="contact-info" data-vivus-hover>
                            <div className="info">
                            <div className="numTopCircle">
                                    1
                                </div>
                                <h4 className="title">{props.titleOne}</h4>
                                <p>
                                    {props.descOne}
                                </p>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                    </div>
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >
                        {/* <!-- Contact Information Start --> */}
                        <div className="contact-info" data-vivus-hover>
                            <div className="info">
                                <div className="numTopCircle">
                                    2
                                </div>
                                <h4 className="title">{props.titleTwo}</h4>
                                <p>
                                {props.descTwo}
                                </p>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                    </div>
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >
                        {/* <!-- Contact Information Start --> */}
                        <div className="contact-info" data-vivus-hover>
                            <div className="info">
                            <div className="numTopCircle">
                                    3
                                </div>
                                <h4 className="title">{props.titleThree}</h4>
                                <p>
                                {props.descThree}
                                </p>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                    </div>
                    <div className="col mb-6"
                    // data-aos="fade-up"
                    >
                        {/* <!-- Contact Information Start --> */}
                        <div className="contact-info" data-vivus-hover>
                            <div className="info">
                            <div className="numTopCircle">
                                    4
                                </div>
                                <h4 className="title">{props.titleFour}</h4>
                                <p>
                                {props.descFour}
                                </p>
                            </div>
                        </div>
                        {/* <!-- Contact Information End --> */}
                    </div>
                </div>

                {/* <!-- Animation Shape Start --> */}
                <div className="shape shape-1 scene">
                    <span data-depth="4"><img src="assets/images/shape-animation/video-shape-1.png" alt="Xetech" /></span>
                </div>
                {/* <!-- Animation Shape End --> */}

            </div>
        </div>
        {/* <!-- Contact Information Section End --> */}
        </>
        </>
    )
}

export default memo(WhyChooseUsSection);