import React, { memo } from "react";

const BlogPageSection = (props) => {
    return (
        <>
            {/* {/* <!-- Work Section Start --> */}
            <div className="section section-padding-t90-b100">
                <div className="container">

                    {/* <!-- Section Title Start --> */}
                    <div className="section-title text-center"
                    // data-aos="fade-up"
                    >
                        <h2 className="title fz-32">{props.title}</h2>
                    </div>
                    {/* <!-- Section Title End --> */}

                    {/* <!-- About Wrapper Start --> */}
                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.imageOne} alt={props.altOne} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleOne}</h3>
                                    <p className="desc">{props.descOne}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.imageTwo} alt={props.altTwo} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleTwo}</h3>
                                    <p className="desc">{props.descTwo}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.imageThree} alt={props.altThree} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleThree}</h3>
                                    <p className="desc">{props.descThree}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image" ><img src={process.env.PUBLIC_URL + props.imageFour} alt={props.altFour} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleFour}</h3>
                                    <p className="desc">{props.descFour}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.imageFive} alt={props.altFive} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleFive}</h3>
                                    <p className="desc">{props.descFive}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image"><img src={process.env.PUBLIC_URL + props.imageSix} alt={props.altSix} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleSix}</h3>
                                    <p className="desc">{props.descSix}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image" ><img src={process.env.PUBLIC_URL + props.imageSeven} alt={props.altSeven} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleSeven}</h3>
                                    <p className="desc">{props.descSeven}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                        {/* <!-- Single Work Start --> */}
                        <div className="col mb-6">
                            <div className="work">
                                <div className="thumbnail">
                                    <div className="image" ><img src={process.env.PUBLIC_URL + props.imageEight} alt={props.altEight} /></div>
                                </div>
                                <div className="info">
                                    <h3 className="title">{props.titleEight}</h3>
                                    <p className="desc">{props.descEight}</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Single Work End --> */}

                    </div>
                    {/* <!-- About Wrapper End --> */}

                </div>
            </div>
            {/* <!-- Work Section End --> */}
        </>
    )
}
export default memo(BlogPageSection);