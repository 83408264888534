import React, { memo } from "react";

const ContactInformationSection = (props) => {
    return (
        <>
            {/* <!-- Contact Information Section Start --> */}
            <div className="section section-padding-t90-b100">
                <div className="container shape-animate">
                    {/* <!-- Section Title Start --> */}
                    <div className="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8"
                    // data-aos="fade-up"
                    >
                        <h2 className="title">{props.title}</h2>
                        <p className="sub-title title-w-90">{props.subtitle}</p>
                    </div>
                    {/* <!-- Section Title End --> */}

                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6 story-icon-redefined text-in-line">
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >
                            {/* <!-- Contact Information Start --> */}
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconOne}`} alt={props.altOne} />
                                </div>
                                <div className="info">
                                    <h4 className="title">{props.titleOne}</h4>
                                    <span className="info-text">{props.titleOneDesc}</span>
                                </div>
                            </div>
                            {/* <!-- Contact Information End --> */}
                        </div>
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >
                            {/* <!-- Contact Information Start --> */}
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconTwo}`} alt={props.altTwo} />
                                </div>
                                <div className="info">
                                    <h4 className="title">{props.titleTwo}</h4>
                                    <span className="info-text">{props.titleTwoDec}</span>
                                </div>
                            </div>
                            {/* <!-- Contact Information End --> */}
                        </div>
                        <div className="col mb-6"
                        // data-aos="fade-up"
                        >
                            {/* <!-- Contact Information Start --> */}
                            <div className="contact-info" data-vivus-hover>
                                <div className="icon">
                                    <img className="svgInject" src={`${process.env.PUBLIC_URL + props.iconThree}`} alt={props.altThree} />
                                </div>
                                <div className="info">
                                    <h4 className="title">{props.titlethree}</h4>
                                    <span className="info-text">
                                        {props.titleThreeDec}
                                        <br />
                                        {/* <a href="#">sales@hasthemes.com</a> */}
                                    </span>
                                </div>
                            </div>
                            {/* <!-- Contact Information End --> */}
                        </div>
                    </div>

                    {/* <!-- Animation Shape Start --> */}
                    <div className="shape shape-1 scene">
                        <span data-depth="4"><img src="assets/images/shape-animation/video-shape-1.png" alt="shape" /></span>
                    </div>
                    {/* <!-- Animation Shape End --> */}

                </div>
            </div>
            {/* <!-- Contact Information Section End --> */}
        </>
    )
}

export default memo(ContactInformationSection);