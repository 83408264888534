import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllFourPostTypeSection from "../Componenets/ServicesAllFourPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const TechnologySpecialists = () => {

    const titleWebsite = "Technology Specialist | Xetech";
    const descriptionWebsite = "Our exceptional services and solutions result from an explicit understanding of your business ideas and operations. Our Bonafede specialists deliver the best services globally. Our solutions are all designed to address the technical, commercial and practical challenges you face daily as a business. Strategic or defensive, business-as-usual or significant structural transformation, we are here to help! ";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="A Global Leader In Technology Services And Digital Transformation."
                pagetitle="Technology Specialists"
                subtitle="Build your software specialist development team comprising experts from every aspect of the SDLC, with complete transparency, flexibility, and scalability"
                bgImage="assets/images/services/technology.jpg"
            />
            <ServicesAllFourPostTypeSection
                title="Technology Specialists Services"
                subtitle="Our exceptional services and solutions are a result of an explicit understanding of your business ideas and operations. Our bonafide specialists deliver the best services globally. Solutions are tailored in a way to meet the enterprise requirement. We strive to ensure that our client has minimum or no reliance on us."
                serviceTitleOne="Artificial Intelligence"
                serviceDescOne="Today technology is transforming to a surprising degree with Artificial Intelligence. AI Integration, Cloud AI, Knowledge Engineering, Machine Learning, and Machine Perception are some of the AI services that we facilitate."
                serviceUrlOne="#"
                serviceImageOne="assets/images/services/artificial-intelligence.png"
                serviceTitleTwo="Business Intelligence"
                serviceDescTwo="A meticulous analysis of the information concerned to a business is dealt with by the globally accepted process of business intelligence. Expedite your business with our dedicated experts of business intelligence."
                serviceUrlTwo="#"
                serviceImageTwo="assets/images/services/business-intelligence.png"
                serviceTitleThree="Big Data"
                serviceDescThree="Big Data Analytics deals with large or complex data sets or information, of different sizes, from various sources. Our professionals are here to lubricate this software service for your business."
                serviceUrlThree="#"
                serviceImageThree="assets/images/services/big-data.png"
                serviceTitleFour="Analysis"
                serviceDescFour="Our skilled and competent analytics professionals can deploy their talents to assist you in embedding automated decision-making in your enterprise. We can plan, create and execute analytical applications as per your necessities."
                serviceUrlFour="#"
                serviceImageFour="assets/images/services/analysis.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(TechnologySpecialists);