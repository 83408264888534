import React, { memo } from 'react';

const WorkFeatureDescSection = (props) => {
    return (
        <>
            <div className="section sec-work-feature" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bgImage})`, position: 'relative', backgroundRepeat: 'no-repeat', backgroundSize:'cover' }}>
                <div className="overlay-feature-bg">
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                            <div className="improve-one__left wow slideInLeft animated" data-wow-delay="100ms" data-wow-duration="2500ms" >
                                <div className="improve-one__img-box">
                                    <div className="improve-one__img">
                                        <img src={`${process.env.PUBLIC_URL + props.imageOne}`} alt="Digital platform" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="improve-one__right">
                                <div className="section-title text-left">
                                    <h2 className="section-title__title">Digital platform including the patient portal</h2>
                                    <p className="mt-3">
                                        XeHealth is designed to provide acute patient care with make hospital a paper-free environment.
                                    </p>
                                    <ul className="list-unstyled improve-one__points">
                                        <li><i className="fas fa-check"></i>Appointments request and booking</li>
                                        <li><i className="fas fa-check"></i>Virtual appointments</li>
                                        <li><i className="fas fa-check"></i>GPS ambulance and patient tracking</li>
                                        <li><i className="fas fa-check"></i>Lab Results and investigations</li>
                                        <li><i className="fas fa-check"></i>Patient education and leaflets</li>
                                        <li><i className="fas fa-check"></i>Patient medication and prescription</li>
                                        <li><i className="fas fa-check"></i>In-hospital patient tracking and check-in.</li>
                                        <li><i className="fas fa-check"></i>An app-based integrated queuing system</li>
                                        <li><i className="fas fa-check"></i>Call center integration</li>
                                        <li><i className="fas fa-check"></i>Telemedicine with remote patient care</li>
                                        <li><i className="fas fa-check"></i>And much more.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(WorkFeatureDescSection);