import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import FooterSection from "../Componenets/FooterSection";
import FunfactSection from "../Componenets/FunfactSection";
import NewServicesPageSection from "../Componenets/NewServicesPageSection";

const ServicesPages = () => {

    const titleWebsite = "Service | Xetech";
    const descriptionWebsite = "Xetech Technology Services helps businesses become future-ready with its offerings across digital technologies, including cloud, IoT, process automation, infrastructure, cybersecurity, blockchain, data and analytics, quality engineering, and business processes.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);


    return (
        <>
            <Header />
            <PageTitleSection
                title="Helping You Achieve End-to-End Digital Transformation"
                pagetitle="Services"
                subtitle="We believe in and nurture Innovation, Advancement and growth introducing Technology"
                bgImage="assets/images/pages/services.jfif"
            />
            <NewServicesPageSection
                title="ABOUT OUR SERVICES"
                // subTitle="A solution for every complex challenge"
                imageOne="assets/images/services/software-consulting.jpg"
                iconOne="assets/images/svg-icons/software-consulting.svg"
                titleOne="Software Consulting"
                urlOne="/softwareconsulting"
                imageTwo="assets/images/services/integration.jpeg"
                iconTwo="assets/images/svg-icons/integration.svg"
                titleTwo="Integration"
                urlTwo="/integration"
                imageThree="assets/images/services/technology.jpg"
                iconThree="assets/images/svg-icons/technology-specialist.svg"
                titleThree="Technology Specialists"
                urlThree="/technologyspecialists"
                imageFour="assets/images/services/testing.jpg"
                iconFour="assets/images/svg-icons/testing.svg"
                titleFour="Testing"
                urlFour="/testing"
                descMain="We strive to deliver excellence across a wide spectrum of technology. Our services comprise of software conceptualization, software architecture & design, software development, database development, cloud computing, software migration, application maintenance & testing. Our smart and enthusiastic team helps the businesses accelerate their efficiency using the latest technologies."
            />
            <FunfactSection
                heading="We help our clients solve business problems, increase
                visibility, and achieve unexpected results"
                titleOne="20+"
                subTitleOne="Year's Experience"
                titleTwo="15"
                subTitleTwo="Active Projects"
                titleThree="130"
                subTitleThree="Projects Done"
                titleFour="250+"
                subTitleFour="Satisfied Clients"
            />
            {/* <ContactSection /> */}
            <FooterSection />
        </>
    )
}

export default memo(ServicesPages);