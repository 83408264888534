import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllFivePostTypeSection from "../Componenets/ServicesAllFivePostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import AboutUsPageSection from '../Componenets/AboutUsPageSection';
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const BrowserApplicationsPage = () => {

    const titleWebsite = "Browser Application | Xetech";
    const descriptionWebsite = "One of the most significant overall trends in web app development revolves around speed. Businesses, customers, and end-user want fast web applications. With years of experience working with multi-tenancy microservice architecture, our team of experts will deliver exceptional results using React.JS, Vue.JS, Angular, ";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitleSection
                title="Our inventiveness is not limited to what device you are using."
                pagetitle="Browser Applications"
                subtitle="Technologize your business with our ground-breaking browser application solutions"
                bgImage="assets/images/technologies/browser.png"
            />
            <AboutUsPageSection
                title=""
                subTitle="Deploy the Right Applications to Respond Quickly to Market Possibilities."
                titleOne="Operational Systems"
                subtitleOne="Because of the growth, restructuring and transformation in market trends, the businesses are also transforming. A new bespoke operational system might be the solution."
                titleTwo="Agile Methodology"
                subtitleTwo="For a feasible approach to designing, rectification via a streamlined prototype, real-time updates on status, and a quick acknowledgement, agile technology is what you need."
                iconOne="assets/images/svg-icons/operationals-system.svg"
                altIconOne="Operational System"
                iconTwo="assets/images/svg-icons/agile.svg"
                altIconTwo="Agile"
                imageOne="assets/images/pages/agile.jpg"
                altOne="Agile"
                imageTwo="assets/images/pages/operations.jpg"
                altTwo="Operation"
            />
            <ServicesAllFivePostTypeSection
                title="Whether you need an internal browser application for your business, or, an app to provide Software-as-a-Service or to revolutionise customer service by providing a portal for your customers to access, choose us for browser application services."
                subtitle="One of the most significant overall trends in web app development revolves around speed. Businesses, customers and end-user want fast web applications. As the business world becomes increasingly web-based, your business applications play a growing role in your success. Picking us as your development partner can give you and your product the edge it needs to build an application fast because we provide a foundation that supports the fundamentals common to all web applications. Our incredible team of designers, developers and analysts develop highly-functional solutions that deliver tangible results."
                serviceTitleOne="UI/UX Design"
                serviceDescOne="User Interface or User experience incorporates all the aspects of the interaction of the user with a software system. To get an end-to-end UI / UX Design service, utilising all the relevant standards, for any software system, meet our unequalled experts."
                serviceUrlOne="#"
                altOne="UI Design"
                serviceImageOne="assets/images/technologies/ui-design.png"
                serviceTitleTwo="Angular"
                serviceDescTwo="As a superheroic frontend framework, used for creating a single-page web application with speed, agility and a strong community for backup, Angular is marketable. We are an unimpeachable Angular framework development corporation with a mission to make web development effortless and quick."
                serviceUrlTwo="#"
                altTwo="Angular"
                serviceImageTwo="assets/images/technologies/angular.png"
                serviceTitleThree="React"
                serviceDescThree="Mainly used for building web user interfaces and featuring component-based architecture, ReactJS is in demand as a JavaScript frontend framework. For a diverse range of reusable react components and full-scale web applications development, our team is the best in every way."
                serviceUrlThree="#"
                altThree="React"
                serviceImageThree="assets/images/technologies/react.png"
                serviceTitleFour="Vue"
                serviceDescFour="Vue.js is known for its flawless, high-performing applications development features incorporating flexible full-stack development at the minimum price. For a real-time and dynamic application with Vue.js for your business, a dedicated Vue.js development company like us can provide great assistance."
                serviceUrlFour="#"
                altFour="Vue js"
                serviceImageFour="assets/images/technologies/vue.png"
                serviceTitleFive="Typescript"
                serviceDescFive="To add optional types to JavaScript, Typescript came into existence. For a large-scale JavaScript application, it supports tools for every browser, for every host, and every OS. Need a Typescript based application, from a reputable software development organisation? Contact us."
                serviceUrlFive="#"
                altFive="Type Script"
                serviceImageFive="assets/images/technologies/typescript.png"
                serviceTitleSix="Pharmacy Integration"
                serviceDescSix="The lab results accumulated in one place can be shared anywhere with ease. Automate your lab data and systems and make it valuable with the lab integration service offered by our esteemed professionals."
                serviceUrlSix="#"
                altSix="Windows Form"
                serviceImageSix="assets/images/technologies/windows-form.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(BrowserApplicationsPage);