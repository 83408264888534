import React, { memo, useState } from "react";
import ButtonDesignSection from "./ButtonDesignSection";
import SliderMain from "react-slick";
/*import BGSlider from 'assets/media/video/video.mp4';*/
// SwiperCore.use([Navigation]);

const Slider = (props) => {

    /*const [VideoBG, setVideoBg] = useState('https://www.xetech.com/wp-content/uploads/2021/07/c568535cb6.mp4');*/
    const [VideoBG, setVideoBg] = useState(process.env.PUBLIC_URL + 'assets/media/video/video.mp4');
    const [videoPlayerStart, setvideoPlayerStart] = useState('none');


    var BgVideoAutoplay = document.getElementById('background-video');
       const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 1,
        // responsive: [
        //     {
        //       breakpoint: 1024,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1,
        //         infinite: true,
        //         dots: true
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 2,
        //         slidesToScroll: 2,
        //         initialSlide: 2
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
    };




    return (
        <>


            <div className="intro-slider-wrap intro-slider-wrap-main-box section overflow-hidden">
                <SliderMain {...settings}>
                    <div className="swiper-slide">
                        {/* <div className="intro-section section overlay" data-bg-color="#000" data-bg-image={require('./assets/images/hero-image/hero-2.jpg')}> */}
                        <div className="intro-section bg-video section overlay" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bannerOne})` }} data-bg-color="#000" data-bg-image={process.env.PUBLIC_URL + 'assets/images/hero-image/hero-2.jpg'}>
                            {/*<div className="sliderBgBlack">*/}
                            {/*</div>*/}
                            {/*<div className="VideoBgSlider">*/}
                            {/*    <video id="background-video" loop="true" onLoadedData={() => {*/}
                            {/*        setvideoPlayerStart('block')*/}
                            {/*    }} autoplay="autoplay" playsinline controls="controls" preload="none" muted poster={process.env.PUBLIC_URL + props.bannerOne} style={{ display: videoPlayerStart }}>*/}
                            {/*        <source src={VideoBG} type="video/mp4" />*/}
                            {/*        <source src={VideoBG} type="video/ogg" />*/}
                            {/*        Your browser does not support the video tag.*/}
                            {/*    </video>*/}
                            {/*</div>*/}
                            {/*<div className="VideoPlayerBG">*/}
                            {/*    <video playsinline="" loop="" autoplay="" preload="auto" id="myVideo" className="VideoPlayerBGVideo">*/}
                            {/*        <source src="https://template.hasthemes.com/exomac/exomac/assets/media/video/local-video.mp4" type="video/mp4" />*/}
                            {/*    </video>*/}
                            {/*</div>*/}
                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">Designing awesome brands & experiences </h2> */}
                                            <h1 className="title">{props.titleOne}</h1>
                                            <div className="desc">
                                                <p>{props.subtitleOne}</p>
                                            </div>
                                            <ButtonDesignSection title="About Us" url='about' style='' />
                                            <ButtonDesignSection title=" Contact Us " url='contact' style='border' />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="swiper-slide">
                        {/* <div className="intro-section section overlay" data-bg-color="#000" data-bg-image={require('./assets/images/hero-image/hero-2.jpg')}> */}
                        <div className="intro-section section overlay" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bannerTwo})` }} data-bg-color="#000" data-bg-image={process.env.PUBLIC_URL + 'assets/images/hero-image/hero-2.jpg'}>
                            {/*<div className="sliderBgBlack">*/}
                            {/*</div>*/}
                            {/*<div className="VideoBgSlider">*/}
                            {/*    <video id="background-video" loop autoPlay>*/}
                            {/*        <source src={VideoBG} type="video/mp4" />*/}
                            {/*        <source src={VideoBG} type="video/ogg" />*/}
                            {/*        Your browser does not support the video tag.*/}
                            {/*    </video>*/}
                            {/*</div>*/}
                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">Designing awesome brands & experiences </h2> */}
                                            <h2 className="title">{props.titleTwo}</h2>
                                            <div className="desc">
                                                <p>{props.subtitleTwo}</p>
                                            </div>
                                            <ButtonDesignSection title="About Us" url='about' style='' />
                                            <ButtonDesignSection title=" Contact Us " url='contact' style='border' />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="swiper-slide">
                        {/* <div className="intro-section section overlay" data-bg-color="#000" data-bg-image={require('./assets/images/hero-image/hero-2.jpg')}> */}
                        <div className="intro-section section overlay" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + props.bannerThree})` }} data-bg-color="#000" data-bg-image={process.env.PUBLIC_URL + 'assets/images/hero-image/hero-2.jpg'}>
                            {/*<div className="sliderBgBlack">*/}
                            {/*</div>*/}
                            {/*<div className="VideoBgSlider">*/}
                            {/*    <video id="background-video" loop autoPlay>*/}
                            {/*        <source src={VideoBG} type="video/mp4" />*/}
                            {/*        <source src={VideoBG} type="video/ogg" />*/}
                            {/*        Your browser does not support the video tag.*/}
                            {/*    </video>*/}
                            {/*</div>*/}
                            <div className="container">
                                <div className="row row-cols-lg-1 row-cols-1">

                                    <div className="col align-self-center">
                                        <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                            {/* <h2 className="title">Designing awesome brands & experiences </h2> */}
                                            <h2 className="title">{props.titleThree}</h2>
                                            <div className="desc">
                                                <p>{props.subtitleTree}</p>
                                            </div>
                                            <ButtonDesignSection title="About Us" url='about' style='' />
                                            <ButtonDesignSection title=" Contact Us " url='contact' style='border' />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </SliderMain>
            </div>
        </>
    )
}

export default memo(Slider);