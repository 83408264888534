import { Logger, LogLevel } from 'msal';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { initConfig } from './Config'

//SS: Build configuration.
const config = initConfig();

// SS: Using react-aad-msal library for Azure AD
// Logging
const logger = new Logger(
    (logLevel, message, containsPii) => {
        console.log("[MSAL]", message);
    },
    {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false
    }
);

//SS: The auth provider should be a singleton.
export const authProvider = new MsalAuthProvider(
    {
        auth: {
            //SS: Setup Azure configuration.
            authority: config.authority,
            clientId: config.clientId,
            redirectUri: config.redirectUri,
            postLogoutRedirectUri: config.postLogoutRedirectUri,

        },

        // Logging should be disabled in production builds.
        system: {
            logger: logger as any
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false,
        }
    },
    {
        scopes: ["openid", "profile"]
    },
    {
        loginType: LoginType.Popup,
        tokenRefreshUri: window.location.origin + "/auth.html"
    }
);
