import React, { useEffect, useState } from 'react';
import axios from "axios";
import DataCareer from '../Jobs/Alljobs';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { useLocation, useParams } from "react-router-dom";

const CarrerInnserDataSection = ({ props }) => {

    const NameParaList = useParams();
    const NamePara = NameParaList.key;

    const [State, setState] = useState(null);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [File, setFile] = useState([]);
    const [Msg, setMsg] = useState('');
    const [ErrorMsg, setErrorMsg] = useState('');
    const [ErrorColor, setErrorColor] = useState('');
    const [Popup, setPopup] = useState('none');
    const [EPopUp, setEPopUp] = useState('none');
    const [Token, setToken] = useState('');
    const [Country, setCountry] = useState('India');

    //const searchPara = useLocation().search;
    //const NamePara = new URLSearchParams(searchPara).get('key');


    useEffect(() => {
        loadCaptchaEnginge(4);
    }, []);

    if (NamePara === '' || NamePara === undefined) {
        window.open("career", "_self")
    }


    var a = DataCareer.filter((item) => {
        return item.key === NamePara
    });

    if (a.length == 0) {
        window.open("career", "_self")
    }

    if (a.[0].key == '' || a.[0].key == null || a.[0].key == undefined) {
        window.open("career", "_self")
    }


    var arrayKey = a.[0].KeyRequirment;

    const sendCareerForm = (event) => {
        event.preventDefault()
        setErrorMsg('');
        var CaptchaField = document.getElementById('user_captcha_input').value;

        if (Country === 'India') {
            if (State === 'select-state' || State === '' || State === undefined || State === null) {
                setErrorMsg('Select State');
                setErrorColor('red');
                return false;
            }
            else {
                setErrorMsg('');
                setErrorColor('red');
            }
        }
        if (Name === '' || Email === '' || Msg === '' || File === '' || CaptchaField === '') {
            setErrorMsg('Filed is empty');
            setErrorColor('red');
            return false;
        }
        if (validateCaptcha(CaptchaField) === false) {
            loadCaptchaEnginge(4);
            setErrorMsg('Captcha is mismatched');
            setErrorColor('red');
            return false;
        }
        else {

            if (validateCaptcha(CaptchaField) === true) {

                loadCaptchaEnginge(4);
                const formData = new FormData();
                formData.append('Name', Name);
                /*formData.append('State', State);*/
                formData.append('ToEmail', Email);
                formData.append('Attachments', File);
                formData.append('Subject', a[0].title + '(' + Country + (State ? (' - ' + State) : '') + ')');
                formData.append('Body', Msg);
                setEPopUp('flex')
                

                axios({
                    url: 'email/ApplyForJob',
                    method: 'POST',
                    mode: 'no-cors',
                    data: formData,
                })
                    .then(function (response) {

                        let statusCode = response.status,
                            success = response.ok;
                        //if (success) {
                        //    setErrorMsg('You have succesfully shared your C.V. with us.')
                        //    setErrorColor('green');
                        //    setPopup('show');

                        //    console.log(ErrorMsg, setPopup)
                        //}
                        if (response.status === 200) {
                            setErrorMsg('')
                            setErrorColor('green');
                            setPopup('show');
                            setEPopUp('none')
                            setState('')
                            setCountry('India')
                        }
                        /*console.log("response :", response.status);*/
                    })
                    .catch(function (error) {
                        /*console.log("error from image :" + error);*/
                    })
            }
            else {
                setErrorMsg('Captcha is mismatched');
                setErrorColor('red');
            }
        }

    }

    const fileHandler = (event) => {
        var fileName = event.target.files[0].name;
        var FileNameExt = '.' + fileName.split('.')[1];
        /*console.log(FileNameExt);*/
        var fileType = event.target.files[0].type;
        /*console.log(fileName, fileType);*/
        var re = /(\.pdf|\.PDF|\.docx|\.dotx|\.docm)$/i;
        if (!re.exec(FileNameExt)) {
            alert("File extension not supported!");
            document.getElementById("fileResume").value = null;
        }
        else {
            /*console.log(event.target.files[0].name);*/
            setFile(event.target.files[0]);
        }
        /*setFile(event.target.files[0]);*/
    };

    var akeyf = a.[0].KeyRequirment;
    let KeyRoles = akeyf.map((e, b) => {
        return <li key={b} > {e} </li>;
    });


    return (
        <>
            <div className="popupShowEarlier" style={{ display: EPopUp === 'none' ? 'none' : 'flex' }} >
                <div className="loaderForm">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <section className="section section-padding pt-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container">
                                <div className="row mt-15 pt-10 pl-5 pr-5" style={{ backgroundColor: '#f2f2f2' }}>
                                    <div className="col-md-12">
                                        <div className="section-title text-left mb-lg-12 mb-sm-8 mb-xs-8"
                                        // data-aos="fade-up"
                                        >
                                            <h1 className="title">Job Description</h1>
                                            <h2 className="mt-5">
                                                {a[0].title}
                                            </h2>
                                            <p>
                                                {a.[0].Descritption}
                                            </p>
                                            <ul>
                                                {KeyRoles}
                                            </ul>
                                            <form className="formCareer" onSubmit={sendCareerForm}>
                                                <div className="row">
                                                    <div className="col-md-6 col-12 mb-6">
                                                        <input type="text" placeholder="Your Name *" pattern="[a-zA-Z][a-zA-Z ]{2,}" minlength="5" value={Name} onChange={(e) => {
                                                            setName(e.target.value)
                                                        }} required />
                                                    </div>
                                                    <div className="col-md-6 col-12 mb-6">
                                                        <input type="Email" placeholder="Your Email *" value={Email} onChange={(e) => setEmail(e.target.value)} required />
                                                    </div>
                                                    <div className="col-md-12 col-12 mb-6">
                                                        <select className="form-select" value={Country} onChange={(e) => {
                                                            if (e.target.value != 'India') {
                                                                setState(null)
                                                            }
                                                            setCountry(e.target.value)
                                                        }}>
                                                            <option value="India">India</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="UAE">UAE</option>
                                                            <option value="United States">United States</option>
                                                        </select>
                                                    </div>
                                                    {Country === 'India' ? <div className="col-md-12 col-12 mb-6">
                                                        <select className="form-select" value={State} onChange={(e) => { setState(e.target.value) }}>
                                                            <option value={null}>Select state</option>
                                                            <option value="UttarPradesh">Uttar Pradesh</option>
                                                            <option value="Punjab">Punjab</option>
                                                            <option value="Haryana">Haryana</option>
                                                            <option value="Rajasthan">Rajasthan</option>
                                                            <option value="Delhi">Delhi</option>
                                                            <option value="Chandigarh">Chandigarh</option>
                                                            <option value="Uttrakhand">Uttrakhand </option>
                                                        </select>
                                                    </div> : ''}
                                                    <div className="col-md-12 col-12 mb-6">
                                                        <input type="file" placeholder="Your File*" onChange={fileHandler} required id="fileResume" className="form-control" />
                                                    </div>
                                                    <div className="col-md-12 col-12 mb-6">
                                                        <textarea placeholder="Type your Message here" minlength="10" value={Msg} onChange={(e) => {
                                                            setMsg(e.target.value)
                                                        }} required ></textarea>
                                                    </div>

                                                    <div className="col-md-6 text-center captcha-design-box">
                                                        <LoadCanvasTemplate />
                                                    </div>
                                                    <div className="col-md-6 mb-2 career-captcha">
                                                        <div><input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" className="career-captcha-input" /></div>
                                                    </div>
                                                    <div className="col-md-12 mt-5 mb-5 text-align-center">
                                                        <p className="text-center" style={{ color: ErrorColor }} >
                                                            {ErrorMsg}
                                                        </p>
                                                    </div>
                                                    <div className="col-12 text-right mb-6">
                                                        <button className="btn btn-primary w-100 btn-hover-secondary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={` modal modalPopupDesign fade ${Popup === 'none' ? '' : 'show'}`} style={{ display: Popup === 'none' ? 'none' : 'block' }}  >
                <div className="modal-dialog modal-dialog-centered" tabindex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Thanks for choosing us.</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                                    setPopup('none')
                                    setName('')
                                    setEmail('')
                                    setMsg('')
                                    setFile('')
                                }} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>We will get back to you soon.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary w-100" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setPopup('none')
                                        setName('')
                                        setEmail('')
                                        setMsg('')
                                        setFile('')
                                        setEPopUp('none')
                                        document.getElementById("fileResume").value = null;
                                        document.getElementById("user_captcha_input").value = null;
                                    }}
                                >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CarrerInnserDataSection;