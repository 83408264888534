//SS: Configuration for react application.

//SS: Azure AD configuration.
const clientIds = [
    { url: "https://localhost:44350", id: "546904b0-5d58-469c-947b-e7bd0ca5fbb6" },
    { url: "https://emrppu.trudoc24x7.info", id: "546904b0-5d58-469c-947b-e7bd0ca5fbb6" },
    { url: "https://beta.trailboss.com", id: "546904b0-5d58-469c-947b-e7bd0ca5fbb6" }
    
];

//SS: build and return required variables.
export const initConfig = (baseUrl = "", clientId = "") => {

    if (!baseUrl) baseUrl = window.location.origin.toLowerCase();

    if (!clientId) {
        var client = clientIds.find((item) => baseUrl == item.url);
        if (client) clientId = client.id;
    }

    return {
        redirectUri: baseUrl + '/dashboard',
        clientId: clientId,
        authority: 'https://login.microsoftonline.com/common',
        baseUrl: baseUrl,
        postLogoutRedirectUri: window.location.origin
    }
}