import React, { memo, useEffect } from "react";
import Header from "../Componenets/Header";
import PageTitleSection from "../Componenets/PageTitleSection";
import ServicesAllPostTypeSection from "../Componenets/ServicesAllPostTypeSection";
import NewsLetterSection from "../Componenets/NewsLetterSection";
import FooterSection from "../Componenets/FooterSection";
import BrandLogoSliderSection from "../Componenets/BrandLogoSliderSection";

const IntegrationPage = () => {

    const titleWebsite = "Integration | Xetech";
    const descriptionWebsite = "We started our operations as a software development and Integration specialist company. Whether it is an API management solution or integration with healthcare devices and machines, service bus or management gateways, Internet-based integrated administrative service, cross-bureau collaboration, system architecture, and integration is something we deliver with utmost efficiency and superior quality.";
    useEffect(() => {
        document.title = titleWebsite;
        document.getElementsByTagName("meta")[2].content = descriptionWebsite;
        document.description = descriptionWebsite;
        window.scrollTo(0, 0);
    }, [titleWebsite, descriptionWebsite]);

    return (
        <>
            <Header />
            <PageTitleSection
                title="Known As Top System Integrators And Microservices Consultants."
                pagetitle="Integration"
                subtitle="Xetech connects your data, applications, APIs, and devices across your IT organization to be more efficient, productive, and agile in current technology trends"
                bgImage="assets/images/services/integration.jpeg"
            />
            <ServicesAllPostTypeSection
                title="Integration Services"
                subtitle="The foremost step is to listen to your vision and ideas. The end-users often have a maximum impact on a business. Grasping their requirements gives a definite idea about the architecture design of the solution. After the integration design, we test for any faults or errors before finalising the integration solution."
                serviceTitleOne="Healthcare Integration"
                serviceDescOne="Integration in the HealthCare industry is a big challenge, and so is the analysis of its data. With technical expertise like ours, HealthCare Integration is not so strenuous anymore."
                serviceUrlOne="#"
                altOne="Healthcare Integration"
                serviceImageOne="assets/images/services/healthcare-tntegration.png"
                serviceTitleTwo="HL7/FHIR Integration"
                serviceDescTwo="To electronically exchange the healthcare information, a standard was specified, often called as FHIR (Fast Healthcare Interoperability Resources). We offer HL7 and FHIR specifications while making use of the data of a Healthcare application."
                serviceUrlTwo="#"
                altTwo="HL7/FHIR Integration"
                serviceImageTwo="assets/images/services/integration.png"
                serviceTitleThree="System Integration"
                serviceDescThree="Access to the same data in real-time to a group of people or a team within a company can reduce the time consumption and increase the profit. Choose system integration service for your business with us."
                serviceUrlThree="#"
                altThree="System Integration"
                serviceImageThree="assets/images/services/system-integration.png"
                serviceTitleFour="Device Integration"
                serviceDescFour="Integrating various devices to allow them to transfer or share information among each other is device integration. We have expertise in eliminating the hurdles coming in the way to attain the same."
                serviceUrlFour="#"
                altFour="Device Integration"
                serviceImageFour="assets/images/services/device-integration.png"
                serviceTitleFive="Claims/ Automation Integration"
                serviceDescFive="Exchanging real-time data across medical and pharmacy is beneficial, especially for identifying patients who need immediate aid. Pharmacy integration will also minimise the call for more costly interventions, thus profiting your business."
                serviceUrlFive="#"
                altFive="Claims/ Automation Integration"
                serviceImageFive="assets/images/services/automation-integration.png"
                serviceTitleSix="Pharmacy Integration"
                serviceDescSix="The lab results accumulated in one place can be shared anywhere with ease. Automate your lab data and systems and make it valuable with the lab integration service offered by our esteemed professionals."
                serviceUrlSix="#"
                altSix="Pharmacy Integration"
                serviceImageSix="assets/images/services/pharmacy-integration.png"
            />
            <NewsLetterSection
                title="Let’s find out how to work together"
                subtitle="Ready to start your project? The contact information collected through this form will only be used to send a response to your inquiry."
            />
            <BrandLogoSliderSection
                title="Designing awesome brands & experiences"
                subtitle="We are an agency located in New York. We think strategy, craft design, develop digital and create motion. To forward your brand and business."
                urlOne="assets/images/brand-logo/trudoc.jpg"
                altOne="Tru Doc"
                //urlTwo="assets/images/brand-logo/worldatwork.jpg"
                //altTwo="World at work"
                urlThree="assets/images/brand-logo/icici-pru.jpg"
                altThree="ICICI Pru"
                urlFour="assets/images/brand-logo/fdb.jpg"
                altFour="FDB"
                urlFive="assets/images/brand-logo/copper-state.jpg"
                altFive="Copper State"
                urlSix="assets/images/brand-logo/oriel.jpg"
                altSix="Oriel"
                urlSeven="assets/images/brand-logo/pathway.jpg"
                altSeven="Pathway"
                //urlEight="assets/images/brand-logo/ssc.jpg"
                //altEight="SSc"
                urlEight="assets/images/brand-logo/glucare.jpg"
                altEight="Glucare"
                urlNine="assets/images/brand-logo/icldc.jpg"
                altNine="ICLDC"
                urlTen="assets/images/brand-logo/hicom.jpg"
                altTen="HICOM"
                urlEleven="assets/images/brand-logo/apeoplebusiness.jpg"
                altEleven="A People Business"
                urlTweleve="assets/images/brand-logo/compass.jpg"
                altTweleve="Compass"
                urlThirteen="assets/images/brand-logo/london-medical-laboratory.jpg"
                altThirteen="London Medical Laboratory"
                urlFourteen="assets/images/brand-logo/trailboss.jpg"
                altFourteen="Trailboss Enterprises"
                urlFifteen="assets/images/brand-logo/texas.jpg"
                altFifteen="Texas Heart - Texas Heart Institute"
            />
            <FooterSection />
        </>
    )
}

export default memo(IntegrationPage);