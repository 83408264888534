import React, { memo } from "react";
import { Link } from "react-router-dom";

const FooterSection = () => {
    return (
        <>
            <div className="footer-section section" style={{ backgroundColor: `#154273` }} data-bg-color="#154273">
                <div className="container">

                    {/* <!-- Footer Top Widgets Start --> */}
                    <div className="row mb-lg-14 mb-md-10 mb-6">

                        {/* <!-- Footer Widget Start --> */}
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-6">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <Link to='/' ><img src="assets/images/logo/logo.svg" alt="Xetech" /></Link>
                                </div>
                                <div className="footer-widget-content">
                                    <div className="content">
                                        <p><a href={"tel:" + "+16692440488"} >US: (+1) 669 244 0488</a> </p>
                                        <p><a href={"tel:" + "+442080809601"} >UK: (+44) 20 8080 9601</a></p>
                                        <p><a href={"tel:" + "+911203512812"} >IN: (+91) 120 3512812</a></p>
                                        <p><a href={"mailto:" + "contact@xetech.com"} >contact@xetech.com</a></p>

                                    </div>
                                    <div className="footer-social-inline">
                                        <a href="https://www.facebook.com/profile.php?id=100057254957799" target="_blank" ><i className="fab fa-facebook-square"></i></a>
                                        <a href="https://www.linkedin.com/company/xetech" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                                        <a href="https://twitter.com/xetech_global" target="_blank"><i className="fab fa-twitter-square"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}

                        {/* <!-- Footer Widget Start --> */}
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 mb-6">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Services</h4>
                                <div className="footer-widget-content">
                                    <ul>
                                        <li><Link to='/softwareconsulting'><span className="menu-text">Software Consulting</span></Link></li>
                                        <li><Link to='/integration'><span className="menu-text">Integration</span></Link></li>
                                        <li><Link to='/technologyspecialists'><span className="menu-text">Technology Specialists</span></Link></li>
                                        <li><Link to='/testing'><span className="menu-text">Testing</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}

                        {/* <!-- Footer Widget Start --> */}
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 mb-6">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Technology</h4>
                                <div className="footer-widget-content">
                                    <ul>
                                        <li><Link to='/applications'><span className="menu-text">Applications</span></Link></li>
                                        <li><Link to='/browserapplications'><span className="menu-text">Browser Applications</span></Link></li>
                                        <li><Link to='/cloudservices'><span className="menu-text">Cloud Services</span></Link></li>
                                        <li><Link to='/mobiledevelopment'><span className="menu-text">Mobile Development</span></Link></li>
                                        <li><Link to='/programmingexpertise'><span className="menu-text">Programming Expertise</span></Link></li>
                                        <li><Link to='/database'><span className="menu-text">Database</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Footer Widget Start --> */}
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-6 mb-6">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Other Links</h4>
                                <div className="footer-widget-content">
                                    <ul>
                                        <li><Link to='/about'><span className="menu-text">About Us</span></Link></li>
                                        <li><Link to='/work'><span className="menu-text">Products</span></Link></li>
                                        <li><Link to='/managesservices'><span className="menu-text">Managed Services</span></Link></li>
                                        {/*<li><Link to='/blog'><span className="menu-text">Blog</span></Link></li>*/}
                                        <li><Link to='/contact'><span className="menu-text">Contact Us</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Footer Widget End --> */}

                    </div>
                    {/* <!-- Footer Top Widgets End --> */}

                    {/* <!-- Footer Copyright Start --> */}
                    <div className="row">
                        <div className="col">
                            <p className="copyright"> © <strong>Xetech</strong> 2022. All Rights Reserved. </p>
                        </div>
                    </div>
                    {/* <!-- Footer Copyright End --> */}

                </div>
            </div>

        </>
    )
}

export default memo(FooterSection);